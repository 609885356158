<script lang="ts">
import {
	_,
	locale
} from 'svelte-i18n';
import { addToCart } from 'Utils/cart/store';
import { getContext } from 'svelte';
import BoxSVG from '../../icons/box-seam.svg';
import Button from 'Components/primitives/Button.svelte';
import FormItem from 'Components/primitives/FormItem.svelte';
import Select, { Option } from 'Components/primitives/Select.svelte';
import type { AddNotification } from './Notifications.svelte';
import type { 
	Attribute,
	OneProductFull,
	ProductAttribute,
	Variation
} from 'Utils/products';

interface SelectedAttributes {
	[property: string]: string | undefined
}

export let product: OneProductFull;
let selectedAttributes: SelectedAttributes = {};
let choosenVariation: Variation[] = [];
const addNotification: AddNotification = getContext('addNotification');
$: currentLang = $locale as 'lv' | 'ru';

const initialTotalStock = (() => {
	return product.variations.reduce((total, variation) => {
		return total + variation.stock.amount - variation.stock.reserved;
	}, 0);
})();

let totalStock: number = initialTotalStock;

function handleAddToCart(id: number) {
	let hasFilled = true;
	product.attributes.forEach((select, i) => {
		if(!selectedAttributes[select.attribute.key]) {
			hasFilled = false;
		}
	});
	if (hasFilled) {
		if (choosenVariation.length === 0 && product.attributes.length === 0) {
			choosenVariation = product.variations;
		}
		addToCart(id, choosenVariation[0].id, 1);
		totalStock = initialTotalStock;
		addNotification({
			type: 'success',
			text: $_('shop.addedToCart')
		});
		selectedAttributes = {};
	} else {
		addNotification({
			type: 'error',
			text: $_('shop.addToCartFailure')
		});
	}
}

function handleClear(key: string) {
	selectedAttributes[key] = undefined;
	delete selectedAttributes[key];
	totalStock = initialTotalStock;
}

function handleSelect(property: string) {
	if (Object.keys(selectedAttributes).length === product.attributes.length) {
		choosenVariation = product.variations.filter((variation) => {
			for (const option of variation.options) {
				if (option.attributeValue.key !== selectedAttributes[option.attribute.key]) {
					return false;
				}
			}
			return true;
		});

		if (choosenVariation.length !== 0) {
			totalStock = choosenVariation[0].stock.amount - choosenVariation[0].stock.reserved;
		} else {
			
			Object.keys(selectedAttributes).forEach((selectedAttribute) => {
				if (selectedAttribute !== property) {
					handleClear(selectedAttribute);
				}
			});
		}
	}
}

function attributeItems(productAttribute: Required<ProductAttribute>) {
	const attribute = productAttribute.attribute as Required<Attribute>;
	let values: Option[] = [];
	
	let fitleredVariations = product.variations.filter(variation => {
		if (variation.stock.amount - variation.stock.reserved <= 0) {
			return false;
		}

		if (
			Object.keys(selectedAttributes).length === 0
			|| Object.keys(selectedAttributes).length === product.attributes.length
		) {
			return true;
		}

		if (selectedAttributes[attribute.key]) {
			return true;
		}

		for (const option of variation.options) {
			if (selectedAttributes[option.attribute.key] === option.attributeValue.key) {
				return true;
			}
		}
		
		return false;
	});
	for (const variation of fitleredVariations) {
		variation.options.forEach((option) => {
			if (option.attribute.key === attribute.key) {
				let itemExists = false;

				values.forEach((item) => {
					if (item.value === option.attributeValue.key) {
						itemExists = true;
						return;
					}
				});

				if(!itemExists) {
					values = [...values,
						{
							value: option.attributeValue.key,
							label: option.attributeValue.name[currentLang]
						}
					];
				}
			}
		});
	}

	return values;
}

</script>

<div class="to-cart">
	{#if totalStock > 0}
		{#key selectedAttributes}
			{#each product.attributes as productAttribute, i}
				<FormItem label={productAttribute.attribute.name[currentLang]}>				
					<Select
						items={attributeItems(productAttribute)}
						bind:value={selectedAttributes[productAttribute.attribute.key]}
						on:clear={() => handleClear(productAttribute.attribute.key)}
						on:select={() => handleSelect(productAttribute.attribute.key)}
					/>
				</FormItem>
			{/each}
		{/key}
		<Button value={$_('shop.addToCart')} on:click={() => {handleAddToCart(product.id);}} />
		<div class="stock">
			<BoxSVG />
			<span class:lastChance={totalStock <= 5}>{$_('shop.inStock')} {totalStock > 5 ? '> 5' : totalStock}</span>
		</div>
	{:else}
		<div class="emptyStock">
			{$_('shop.outOfStock')}
		</div>
	{/if}
</div>

<style lang="scss">
	@import '../../styles/mixins';

	.to-cart {
		display: grid;
		grid-gap: 8px;
		padding: 10px;
		background: white;
		border-radius: 5px;

		.stock {
			text-align: right;
			display: flex;
			gap: 10px;
			align-items: center;
			justify-content: end;
			color: #00000085;

			.lastChance {
				color: #dc143c;
			}

			> :global(svg) {
				width: 20px;
				height: 20px;
			}
		}

		.emptyStock {
			font-weight: 500;
			color: #EB0000;
		}

		@include desktop {
			padding: 20px;
		}
	}
</style>