<script lang="ts">
	
</script>

<form on:submit|preventDefault>
	<slot></slot>
</form>

<style lang="scss">
	form {
		display: flex;
		flex-direction: column;
		gap: 10px;
	}
</style>