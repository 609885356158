<script lang="ts">
	import {
		_,
		locale
	} from 'svelte-i18n';
	import { categories } from 'Utils/categories/store';
	import { link } from 'svelte-navigator';
	import Loader from 'Components/primitives/Loader.svelte';
	import type { Category } from 'Utils/categories/types';

	interface Breadcrumb {
		link: string,
		name: string | undefined
	}

	let currentLang = $locale as 'lv' | 'ru';
	$: currentLang = $locale as 'lv' | 'ru';

	let breadcrumbs: Breadcrumb[] = [
		{link: `/${currentLang}`, name: $_('page.equipment')}
	];

	let categoryPathArray: string[] = location.pathname.split('/').slice(3);

	function collectCrumbs(array: string[], categories: Category | undefined, link: string): void {
		if(array.length > 0) {
			let slug = array[0];
			let name = categories?.children[slug].name[currentLang];
			link = `${link }/${ slug}`;
			breadcrumbs.push({link, name});
			array.shift();
			return collectCrumbs(array, categories?.children[slug], link);
		}
		return;
	}

	$categories.then(categories =>{
		collectCrumbs(categoryPathArray, categories, `/${currentLang}/shop`);
	});

</script>

<div class="breadcrumbs">
	{#await $categories}
		<Loader />
	{:then}				
		{#each breadcrumbs as breadcrumb, index}
			{#if index !== breadcrumbs.length - 1}
				<a class="crumb" href={breadcrumb.link} use:link>
					{breadcrumb.name}
				</a>
			{:else}
				<span class="crumb">
					{breadcrumb.name}
				</span>
			{/if}
		{/each}
	{/await}
</div>

<style lang="scss">
	.breadcrumbs {
        padding: 0 12px;
        border-radius: 8px;
        
        .crumb {
            font-size: 18px;
            padding: 4px;
            opacity: .8;
            transition: opacity .2s ease-in-out;

			&:after {
				content: "›";
			}

			&:last-child:after {
				content: "";
			}

        }

		a.crumb:hover {
			opacity: 1;
		}
    }
</style>