<script lang="ts">
	import { _ } from 'svelte-i18n';
	import { meta } from 'Utils/meta/store';
	import AddressSVG from '../../icons/geo-alt.svg';
	import EmailSVG from '../../icons/envelope.svg';
	import PhoneSVG from '../../icons/telephone.svg';
	
	meta.set({
		title: $_('page.howToFindUs'),
		description: '',
		keywords: ''
	});
</script>
<header>
	<h1>{$_('page.howToFindUs')}</h1>
</header>
<section>
	<article class="map">
		<iframe title="BikerToyz" id="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2174.473484223644!2d24.173585!3d56.974951000000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46eece59b3b2f3fd%3A0x1d69bd4eba710a64!2zQnLEq3bEq2JhcyBnYXR2ZSAyNTAsIFZpZHplbWVzIHByaWVrxaFwaWxzxJN0YSwgUsSrZ2EsIExWLTEwMzk!5e0!3m2!1sru!2slv!4v1424318474221" width="100%" height="400px"></iframe>
	</article>
</section>
<div class="divider">
	<section>
		<header>
			<h2>{$_('howToFindUs.workingHours')}</h2>
		</header>
		<article class="white time">
			<div>
				<span class="bold">{$_('day.monday')}:</span>
				<span class="blur">10:00 – 20:00</span>
			</div>
			<div>
				<span class="bold">{$_('day.tuesday')}:</span>
				<span class="blur">10:00 – 20:00</span>
			</div>
			<div>
				<span class="bold">{$_('day.wednesday')}:</span>
				<span class="blur">10:00 – 20:00</span>				
			</div>
			<div>
				<span class="bold">{$_('day.thursday')}:</span>
				<span class="blur">10:00 – 20:00</span>				
			</div>
			<div>
				<span class="bold">{$_('day.friday')}:</span>
				<span class="blur">10:00 – 20:00</span>				
			</div>
			<div>
				<span class="bold red">{$_('day.saturday')}:</span>
				<span class="red blur">10:00 – 20:00</span>				
			</div>
			<div>
				<span class="bold red">{$_('day.sunday')}:</span>
				<span class="red blur">10:00 – 18:00</span>				
			</div>
		</article>
	</section>
	<section>
		<header>
			<h2>{$_('howToFindUs.contacts')}</h2>
		</header>
		<article class="white contacts">
			<div>
				<AddressSVG width="24px" height="24px" />
				<address>
					<a href="geo:56.97494508231283,24.173582096538485">
						Latvija, Rīga, Brīvības gatve 250 LV-1039, {$_('howToFindUs.gsVirsi')}
					</a>
				</address>
			</div>
			<div>
				<PhoneSVG width="24px" height="24px" />
				<a href="tel:+37120442233" class="bold">+371 20442233</a>
			</div>
			<div>
				<EmailSVG width="24px" height="24px" />
				<a href="mailto: pasutijumi@bikertoyz.lv" target="_blank">pasutijumi@bikertoyz.lv</a>
			</div>
		</article>
	</section>
</div>

<style lang="scss">
	@import '../../styles/mixins';

	.map {
		display: flex;
		justify-content: center;
		
		> iframe {
			border-radius: 8px;
			box-shadow: 0 0 20px 1px rgb(0 0 0 / 10%);

			@include desktop {
				margin: 20px 0;
			}
		}
	}
	.white {
		display: flex;
		flex-direction: column;
		gap: 8px;
		border-radius: 5px;

		@include mobile {
			padding: 0 20px;
		}
	}

	.blur {
		filter: blur(10px);
	}
	
	.bold {
		font-weight: 600;
	}

	.red {
		color: #dc143c;
	}

	.divider {
		display: flex;
		flex-direction: column;
		gap: 14px;
		
		@include desktop {
			display: grid;
			grid-gap: 20px;
			align-self: center;
			grid-template-columns: repeat(2, 1fr);
			width: 70%;
		}

		.contacts {

			a {
				padding: 5px 0;
			}

			> div {
				align-items: center;
				display: grid;
				grid-gap: 15px;
				grid-template-columns: 22px 1fr;

				@include desktop {
					grid-gap: 7px;
					grid-template-columns: 32px 1fr;
				}

				> :global(svg) {
					justify-self: center;
					width: 22px;
					height: 22px;
				}
			}
		}
		.time > div {
			display: flex;
			justify-content: space-between;
		}
	}
</style>