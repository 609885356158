<script lang="ts">
	export let label: string;
	export let errors: string[] = [];
</script>

<!-- svelte-ignore a11y-label-has-associated-control -->
<div class="item">
	<span>{label}</span>
	<div>
		<slot></slot>
		{#if errors.length > 0}
			{#each errors as error}
				<span class="error-text">{error}</span>
			{/each} 
		{/if}
	</div>
</div>

<style lang="scss">
	@import '../../styles/mixins';

	.item {
		display: grid;
		align-items: center;
		grid-gap: 6px;
		grid-template-columns: minmax(0, auto);
		width: 100%;
		cursor: pointer;

		@include desktop {
			grid-template-columns: 1fr 300px;
		}

		.error-text {
			color: #EB0000;
			margin: 4px 0 0 10px;
			display: block;
		}
	}
</style>
