<script lang="ts">
    import { 
    	_,
    	locale
    } from 'svelte-i18n';
    import { Link } from 'svelte-navigator';
    import PopUp from 'Components/widgets/PopUp.svelte';

    const currentYear: number = new Date().getFullYear();
    let showRequisites = false;
</script>

<footer>
    <div class="links">
        <span on:click="{() => showRequisites = true}">{$_('footer.requisites')}</span>
        <Link to="/{$locale}/privacy">{$_('footer.privacy')}</Link>
        <Link to="/{$locale}/rules">{$_('footer.rules')}</Link>
    </div>
    <div>
        <span class="copyright"> © BIKER TOYZ 2013 - {currentYear}</span>
    </div>
    
    {#if showRequisites}
        <PopUp on:close="{() => showRequisites = false}">
            <h3 slot="header">{$_('footer.requisites')}</h3>
            <div slot="content" class="requisites">
                <div>
                    <span>{$_('requisites.companyName')}:</span>
                    <span>”VES Cargo”, SIA</span>
                    <span>{$_('requisites.registrationNumber')}:</span>
                    <span>40103595127</span>
                    <span>{$_('requisites.pvnNumber')}:</span>
                    <span>LV40103595127</span>   
                </div>
                <div>
                    <span>{$_('requisites.entityAddress')}: </span>
                    <span>Latvija, Rīga, Rātsupītes iela 3 - 55, LV-1067</span>
                    <span>{$_('requisites.realAddress')}:</span>
                    <span>Latvija, Rīga, Brīvības gatve 250, LV-1039</span>
                </div>
                <div>
                    <span>{$_('requisites.bankName')}:</span>
                    <span>“Swedbank”, AS</span>
                    <span>{$_('requisites.bankCode')}:</span>
                    <span>HABALV22</span>
                    <span>{$_('requisites.bankAcc')}:</span>
                    <span>LV52HABA0551034409443</span>
                </div>
            </div>
        </PopUp>
    {/if}
</footer>

<style lang="scss">
    footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 15px;
        gap: 4px;
        padding-bottom: 20px;
    }
    .links {
        display: flex;
        gap: 8px;

        span,
        :global(a) {
            padding: 4px;
            cursor: pointer;
            opacity: .8;
            transition: opacity .2s;

            &:hover {
                opacity: 1;
            }
        }
    }

    .copyright {
        opacity: .8;
    }

    .requisites {
        display: grid;
        grid-gap: 20px;

        > div {
            display: grid;
            column-gap: 15px;
            row-gap: 5px;
            grid-template-columns: 1fr 2fr;
        }
    }
</style>