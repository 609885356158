<script lang="ts">
	import { link } from 'svelte-navigator';
	import { locale } from 'svelte-i18n';
	import { updateCategories } from 'Utils/categories/store';
	import Image from 'Components/primitives/Image.svelte';
	import type { Category } from 'Utils/categories/types';
import type { Language } from 'Utils/general/types';
	
	let currentLang = $locale as Language;
	$: currentLang = $locale as Language;
	export let categories: Category | undefined = undefined;
	export let root = false;
updateCategories();
</script>
<svelte:options immutable />
<section>
	<article class="categories">
		{#if categories}
			{#each Object.entries(categories.children) as [path, category]}
				<a href={root ? `/${currentLang}/shop/${path}` : `${location.pathname}/${path}`} class="link" use:link>
					<Image src="{category.imageUrl}" alt="{category.name[currentLang]}" />
					<span>{category.name[currentLang]}</span>
				</a>
			{/each}
		{/if}
	</article>
</section>

<style lang="scss">
    @import '../../styles/mixins';

	.categories {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 4px;

        @include desktop {
            display: flex;
            justify-content: center;
            align-items: baseline;
            gap: 4px;
            flex-wrap: wrap;
        }

        .link {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 4px;
            transition: transform .15s ease-in-out;
            transition: opacity .15s ease-in-out;
            text-align: center;

            @include desktop {
                opacity: .8;
                
                &:hover {
                    opacity: 1;
                    transform: scale(1.1);
                }
            }

            > :global(img) {
                padding: 2px;
                width: 100px;
                height: 100px;

                @include desktop {
                    padding: 8px;
                }
            }

        }

        :global(svg) {
            padding: 18px;
        }
    }
</style>

