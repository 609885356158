<script lang="ts">
	import { _ } from 'svelte-i18n';
	import { meta } from 'Utils/meta/store';
	import { query } from 'Utils/general/utils';
	import { useParams } from 'svelte-navigator';
	import Loader from 'Components/primitives/Loader.svelte';
	import NotFound from './NotFound.svelte';

	interface Order {
		code: string
	}

	let params = useParams();
	let order: Promise<Order> = query(`/api/shop/orders/id/${$params.id}?fields=code`);

	meta.set({
		title: $_('page.orderSuccess'),
		description: '',
		keywords: ''
});
</script>

<header>
	<h1>{$_('page.orderSuccess')}</h1>
</header>
<section>
	<article>
		{#await order}
			<Loader />
		{:then order}
			<span>
				{$_('shop.orderSuccess')} {order.code}
			</span>
		{:catch}
			<NotFound />
		{/await}
	</article>
</section>

<style lang="scss">

</style>

