<script lang="ts">
	import { 
		_,
		locale
	} from 'svelte-i18n';
	import {
		changeQuantity,
		clearCart,
		deleteFromCart,
		items
	} from 'Utils/cart/store';
	import { getContext } from 'svelte';
	import {
		getProductsByIds,
		OneProductFull,
		Variation
	} from 'Utils/products';
	import {
		link,
		useNavigate
	} from 'svelte-navigator';
	import {
		normalizeData,
		toMoney
	} from 'Utils/general/utils';
	import Button from 'Components/primitives/Button.svelte';
	import CartSummary from './CartSummary.svelte';
	import Image from 'Components/primitives/Image.svelte';
	import isMobile from 'Utils/general/deviceDetector';
	import Loader from 'Components/primitives/Loader.svelte';
	import TrashSVG from '../../icons/trash.svg';
	import type { AddNotification } from './Notifications.svelte';
	import type { Language } from 'Utils/general/types';
	import type { WithID } from 'Utils/request/types';

	let currentLang = $locale as Language;
	$: currentLang = $locale as Language;
	const navigate = useNavigate();
	const addNotification: AddNotification = getContext('addNotification');

	$: totalPrice = 0;

	interface Products {
		[id: number]: {
			image: string,
			price: number,
			name: string,
			link: string,
			variations: Variation[]
		}
	}
	
	let productIds: number[] = [];
	let products: Products = {};
	$items.forEach(item => productIds.push(item.id));
	
	let productsData = getProductsByIds(`[${productIds.toString()}]`);
	productsData.then( data => {
		if (Array.isArray(data) && data.length === 0) {
			addNotification({
				type: 'alert',
				text: $_('shop.someItemsNotAvaliable')
			});
			return clearCart();
		}
		let normalData = normalizeData<OneProductFull>(data as unknown as WithID<OneProductFull[]>);
		products = $items.reduce((products, product, index) => {
			if(normalData[product.id]) {
				let choosenVariation: Variation | undefined = (() => {
					for (const variation of normalData[product.id].variations) {
						if (variation.id === product.attributes) {
							return variation;
						}
					}
				})();
				if (
					choosenVariation
					&& choosenVariation.stock.amount - choosenVariation.stock.reserved > 0
				) {
					return {
						...products,
						[product.id]: {
							image: normalData[product.id].mainImage.thumbs[0].url,
							price: normalData[product.id].specialPrice > 0 ? 
								normalData[product.id].specialPrice :
								normalData[product.id].price,
							name: normalData[product.id].name,
							link: normalData[product.id].link,
							variations:  normalData[product.id].variations
						}
					} as Products;
				}				
			}

			deleteFromCart(index);
			addNotification({
				type: 'alert',
				text: $_('shop.someItemsNotAvaliable')
			});
			
			return products;
		}, {} as Products);
		countTotal();
	});

	function countTotal() {
		let calculatedPrice = 0;
		$items.forEach(item => {
			calculatedPrice += products[item.id].price * item.quantity;
		});
		totalPrice = calculatedPrice;
	}

	function handleDelete(index: number) {
		return () => {
			deleteFromCart(index);
			countTotal();
		};
	}

</script>
<section>
	<article>
		{#await productsData}
			<Loader />
		{:then} 	
			<div class="items">
				{#each $items as item, i (i)}
					<div class="item">
						<a href="/{$locale}/product/{products[item.id].link}" class="image" use:link>
							<Image src={products[item.id].image} alt={products[item.id].name} />
						</a>
						<div class="data">
							<div class="header">
								<span>{products[item.id].name}</span>
								<div class="delete" on:click={handleDelete(i)}>
									<TrashSVG />
								</div>
							</div>
							<div class="details">
								<div class="attributes">
									{#each products[item.id].variations as variation}
										{#if variation.id === item.attributes}
											{#each variation.options as option}	
												<div>
													<span>{option.attribute.name[currentLang]}:</span>
													<span>{option.attributeValue.name[currentLang]}</span>
												</div>
											{/each}
										{/if}
									{/each}
								</div>
								<div class="quantity">
									<Button 
										type="small"
										on:click={() => {changeQuantity(i, false); countTotal();}}
										value="-"
										disabled={item.quantity === 1}
									/>
									<span>
										{item.quantity}
									</span>
									<Button
										type="small"
										on:click={() => {changeQuantity(i, true); countTotal();}}
										value="+"
										disabled={(() => {
											for (const variation of products[item.id].variations) {
												if (variation.id === item.attributes) {
													if (
														item.quantity
														>= variation.stock.amount
														- variation.stock.reserved
													) {
														return true;
													}
													return false;
												}
											}
											return false;
										})()}
									/>
								</div>
								{#if !isMobile}
									<span>&euro; {toMoney(products[item.id].price)}</span>
								{/if}
								<span>&euro; {toMoney(products[item.id].price * item.quantity)}</span>
							</div>
						</div>
					</div>
				{/each}
			</div>
			<CartSummary total={totalPrice}>
				<Button value={$_('cart.placeOrder')} on:click={() => navigate(`/${currentLang}/order`)} />
			</CartSummary>
		{:catch error}
			<p style="color: #EB0000">{$_('noConnection')}</p>
		{/await}
	</article>
</section>


<style lang="scss">
    @import '../../styles/mixins';

	article {
		display: grid;
		grid-gap: 14px;

		@include desktop {
			grid-gap: 24px;
			grid-template-columns: 3fr 1fr;
        }
		
		.items {
			display: grid;
			grid-gap: 10px;
			align-self: start;

			.item {
				display: grid;
				grid-template-columns: min-content 1fr;
				grid-gap: 10px;
				padding: 10px;
				background-color: white;
				border-radius: 6px;
		
				.image {
					width: 50px;
					height: 100%;

					@include desktop {
						width: 100px;
						height: 100px;
					}
				}

				.data {
					display: grid;
					grid-template-rows: min-content 1fr;
					grid-gap: 6px;

					@include desktop {
						padding: 10px;
					}

					.header {
						display: flex;
						justify-content: space-between;
						font-weight: 600;
						font-size: 17px;
						align-items: center;
					}

					.details {
						display: grid;
						grid-template-columns: min-content 1fr max-content;
						grid-gap: 10px;
						align-content: center;
						align-items: center;

						@include desktop {
							grid-template-columns: repeat(4, 1fr);
						}

						.quantity {
							display: flex;
							gap: 10px;
							align-items: center;

							@include mobile {
								justify-content: center;
							}
						}

						.attributes > div {
							display: flex;
							gap: 10px;
						}
					}

			
					.delete {
						height: 24px;
						width: 24px;
						opacity: .6;
						transition: opacity .2s ease-in;
						cursor: pointer;
			
						&:hover {
							opacity: 1;
						}
					}
				}
			}
		}
	}
</style>