<script lang="ts">
	import { Route } from 'svelte-navigator';
	import ShopCategory from './ShopCategory.svelte';
	import type { Category } from 'Utils/categories/types';

	export let isRoot = false;
	export let category: Category;

	const children = Object.values(category.children);
</script>
{#if children.length === 0}
	<Route path="/{category.link}">
		<ShopCategory category={category} />
	</Route>
{:else}
	<Route path="{!isRoot ? `/${category.link}/*` : '/*'}">
		<Route path="/">
			<ShopCategory category={category} />
		</Route>
		{#each children as child}
			<svelte:self category={child} />
		{/each}
	</Route>
{/if}
