import { startTranslate } from 'Utils/general/i18n';
import App from './App.svelte';
if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        navigator.serviceWorker.register('/swclient.js').
            then(registration => {
            console.log('SW registered: ', registration);
        }).catch(registrationError => {
            console.log('SW registration failed: ', registrationError);
        });
    });
}
startTranslate();
const app = new App({
    target: document.body
});
