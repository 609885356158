<script lang="ts">
	import { clickOutside } from 'Utils/general/clickOutside';
	import { createEventDispatcher } from 'svelte';
	import { 
		fade,
		fly
	} from 'svelte/transition';
	import CloseSVG from '../../icons/xlg.svg';

	const dispatch = createEventDispatcher();
	const close = () => dispatch('close');

	const handleKeydown = (e: KeyboardEvent) => {
		if (e.key === 'Escape' as string) {
			close();
			return;
		}
	};
	
</script>

<svelte:window on:keydown={handleKeydown}/>

<div class="popup" transition:fade="{{ duration: 200}}">
	<section
		class="box"
		transition:fly="{{ y: 200, duration: 200}}"
		use:clickOutside 
		on:click_outside={close}
	>
		<header>
			<span>
				<slot name="header"></slot>
			</span>
			<span class="close" on:click={close}>
				<CloseSVG  width="28px" height="28px" />
			</span>
		</header>
		<article>
			<slot name="content"></slot>
		</article>
	</section>
</div>


<style lang="scss">
    @import '../../styles/mixins';

	.popup {
		display: flex;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.5);
		z-index: 99;
		will-change: opacity;
		justify-content: center;
		align-items: center;

		.box {
			max-width: 90%;
			max-height: 70vh;
			overflow: auto;
			will-change: transform;
			border-radius: 8px;
			background: #FFF;

			@include desktop {
				max-width: 40%;
			}

			header {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;
				padding: 12px 15px;
	
				.close {
					display: flex;
					cursor: pointer;
					opacity: .6;
			
					&:hover {
						opacity: 1;
					}
				}
			}

			article {
				padding: 0px 15px 12px 15px;
			}
		}
	}

</style>