<script lang="ts">
	import { 
		_,
		locale
	} from 'svelte-i18n';
	import { fade } from 'svelte/transition';
	import { 
		Link,
		link,
		navigate,
		useLocation
	} from 'svelte-navigator';
	import { switchLocale } from 'Utils/general/i18n';
	import BagSVG from '../../icons/bag.svg';
	import ContentWrapper from 'Components/layouts/ContentWrapper.svelte';
	// import PersonSVG from '../../icons/person.svg';
	import { quantityInCart } from 'Utils/cart/store';
	import GeoSVG from '../../icons/geo-alt.svg';
	import HomeSVG from '../../icons/columns-gap.svg';
	import InfoSVG from '../../icons/info.svg';
	import isMobile from 'Utils/general/deviceDetector';
	import LeftArrowSVG from '../../icons/arrow-left-simple.svg';
	import LogoSVG from '../../icons/logo.svg';
	import TranslateSVG from '../../icons/translate.svg';

	const mainRegex = /^\/(lv|ru)$/;
	const shopRegex = /^\/(lv|ru)\/shop/;
	const currentLocation = useLocation();
	$: pathLength = $currentLocation.pathname.split('/').length - 1;

	function getEquipmentProps({ location }: Link.GetPropsParams): object {
		const isActive = location.pathname.match(mainRegex)
			? true
			: location.pathname.match(shopRegex);

		return isActive ? { class: 'active' } : {};
	}

	function getProps ({ href, isPartiallyCurrent, isCurrent }: Link.GetPropsParams): object {
		const isActive = $locale && href === `/${ $locale}` ? isCurrent : 
			isPartiallyCurrent || isCurrent;

		return isActive ? { class: 'active' } : {};
	}

</script>
<header>
	<ContentWrapper>
		<div class="content">
			{#if isMobile}
				<div class="secondary-menu">
					{#if pathLength > 1}
						<div on:click={() => {navigate(-1);}}>
							<LeftArrowSVG />
						</div>
					{/if}
				</div>
			{/if}
			<div class="logo">
				<a href="/{$locale}" aria-label="BikerToyz Logo" use:link>
					<LogoSVG alt="BikerToyz" />
				</a>
			</div>
			{#if !isMobile}
				<nav class="menu">
					<Link to="/{$locale}/" getProps={getEquipmentProps}>
						{$_('page.equipment')}
					</Link>
					<Link to="/{$locale}/howtofindus" {getProps} >
						{$_('page.howToFindUs')}
					</Link>
					<Link to="/{$locale}/about" {getProps}>
						{$_('page.aboutUs')}
					</Link>
				</nav>
			{/if}
			<nav class="secondary-menu">
				<div on:click={() => {switchLocale($locale === 'lv' ? 'ru' : 'lv');}}>
					<TranslateSVG width="24px" height="24px" />
				</div>
				{#if !isMobile}
					<Link to="/{$locale}/cart" {getProps} aria-label="{$_('page.cart')}">
						<BagSVG width="24px" height="24px" />
						{#if $quantityInCart > 0}
							<div class="cart-indicator" transition:fade={{duration: 200}} >{$quantityInCart}</div>
						{/if}
					</Link>
				{/if}
				<!-- <div>
					<PersonSVG width="24px" height="24px" />
				</div> -->
			</nav>
		</div>
	</ContentWrapper>
</header>
{#if isMobile}
	<nav class="mobile-menu">
		<Link to="/{$locale}/" getProps={getEquipmentProps}>
			<HomeSVG />
			{$_('page.equipment')}
		</Link>
		<Link to="/{$locale}/howtofindus" {getProps} >
			<GeoSVG />
			{$_('page.howToFindUs')}
		</Link>
		<Link to="/{$locale}/about" {getProps}>
			<InfoSVG />
			{$_('page.aboutUs')}
		</Link>
		<Link to="/{$locale}/cart" {getProps}>
			<BagSVG />
			{#if $quantityInCart > 0}
				<div class="cart-indicator" transition:fade={{duration: 200}} >{$quantityInCart}</div>
			{/if}
			{$_('page.cart')}
		</Link>
	</nav>
{/if}
<style lang="scss">
	@import '../../styles/mixins';
	
	:global(.active) {
		opacity: 1 !important;
	}

	header {
		background-color: #282828;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		height: 50px;
		z-index: 98;

		@include desktop {
			height: 64px;
		}

		@include mobile {
			padding-top: env(safe-area-inset-top);
			-webkit-transform: translateY(0);
			transform: translateY(0);
		}

		.content {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: stretch;
			height: 100%;
			gap: 16px;
			padding: 0 12px;

			@include desktop {
				padding: 0;
			}

			.logo {

				@include mobile {
					position: fixed;
					transform: translateX(-50%);
					left: 50%;
				}

				:global(a) {
					height: 100%;
					width: 120px;
					display: flex;
					padding: 9px 0;
			
					> :global(svg) {
						width: 100%;
						height: 100%;
					}
			
					@include desktop {
						width: 170px;
						padding: 12px;
					}
				}
			}

			@include desktop {
				.menu {
					display: flex;
					position: absolute;
					width: max-content;
					height: 100%;
					align-items: stretch;
					justify-content: center;
					gap: 8px;
					right: 50%;
					transform: translate(50%, 0);

					> :global(a)  {
						display: flex;
						color: #FFF;
						opacity: .6;
						padding: 0 4px;
						align-items: center;
						transition: opacity .2s ease-in;
					
						&:hover {
							opacity: 1;
						}
					}
				}
			}

			.secondary-menu {
				display: flex;
				align-items: stretch;
				gap: 8px;

				> :global(a),
				> div {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 32px;
					height: 100%;
					padding: 0 4px;
					opacity: .6;
					transition: opacity .2s ease-in;

					@include desktop {
						width: 36px;
					}

					> :global(svg) {
						filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(168deg) brightness(102%) contrast(105%);
					}
								
					&:hover {
						cursor: pointer;
						opacity: 1;
					}	
				}
			}
		}
	}

	.cart-indicator {
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 12px;
		position: absolute;
		border-radius: 50%;
		background-color: #dc143c;
		padding: 3px;
		color: #fff;
		width: 15px;
    	height: 15px;
    	bottom: 20px;
    	right: 6px;
		
		@include desktop {
			width: 18px;
			height: 18px;
			bottom: 10px;
			right: 0;
		}
	}

	.mobile-menu {
		display: flex;
		position: fixed;
		flex-direction: row;
		justify-content: space-around;
		padding-bottom: env(safe-area-inset-bottom);
		-webkit-transform: translateY(0);
		transform: translateY(0);
		background: #282828;
		font-size: 13px;
		z-index: 98;
		bottom: 0;
		right: 0;
		left: 0;

		> :global(a) {
			display: flex;
			flex-direction: column;
			align-self: center;
			align-items: center;
			padding: 6px 6px;
			gap: 4px;
			color: #fff;
			opacity: .6;

			:global(svg) {
				width: 24px;
				height: 24px;
			}
		}
	}


</style>