<script context="module" lang="ts">
	export interface NotificationType {
		id?: number,
		type: 'success' | 'error' | 'alert',
		text: string,
		timer?: number
	}

	export type AddNotification = (notification: NotificationType) => void;
	export type DeleteNotification = (id: number) => void;
</script>
<script lang="ts">
	import {
		fade,
		fly
	} from 'svelte/transition';
	import { setContext } from 'svelte';
	import { 
		writable,
		Writable
	} from 'svelte/store';
	import isMobile from 'Utils/general/deviceDetector';

	let notifications: Writable<NotificationType[]> = writable([]);
	let count = 0;

	function addNotification(notification: NotificationType): void {
		notifications.update((current) => {
			const index = count + 0;
			notification.id = index;
			notification.timer = setTimeout(() => {
				deleteNotification(index);
			}, 3000) as unknown as number;
			count += 1;
			return [...current,
				notification];
		});
	}

	function deleteNotification(index: number): void {
		notifications.update((current: NotificationType[]) => {
			return current.filter((notification) => notification.id !== index);
		});
	}

	setContext('addNotification', addNotification);
	setContext('deleteNotification', deleteNotification);

</script>

<div class="box">
	{#each (isMobile ? $notifications.reverse() : $notifications) as notification (notification.id)}
		<div 
			class="{notification.type} notification"
			in:fly="{{y: isMobile ? -200 : 200, duration: 300}}"
			out:fade="{{ duration: 300}}"
		>
			{notification.text}
		</div>
	{/each}
</div>
<slot></slot>

<style lang="scss">
    @import '../../styles/mixins';

	.box {
		position: fixed;	
		z-index: 98;

		@include desktop {
			bottom: 20px;
			right: 20px;
		}

		@include mobile {
			top: 70px;
			right: 20px;
			left: 20px;
		}
	}

	.notification {
		margin-bottom: 10px;
       	width: 100%;
        height: auto;
        color: #fff;
        padding: 12px;
        border-radius: 8px;
		-webkit-box-shadow: 0 0 10px rgb(34 36 38 / 30%);
		box-shadow: 0 0 10px rgb(34 36 38 / 30%);

		@include desktop {
			margin-top: 10px;
			width: 250px;
		}
    }
	
	:global(.success) {
		background-color: #3ec13e;
	}

	:global(.error) {
		background-color: #EB0000;
	}

	:global(.alert) {
		background-color: #ffaa07;
	}
</style>