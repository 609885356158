import { mustUpdate } from './updater';
import { writable } from 'svelte/store';
async function loadPages() {
    const cachedPages = localStorage.getItem('pages');
    if (cachedPages) {
        return JSON.parse(cachedPages);
    }
    try {
        const response = await fetch('/api/general/pages');
        const resp = await response.json();
        if (response.ok) {
            localStorage.setItem('pages', JSON.stringify(resp.message));
            return resp.message;
        }
        throw new Error('Error');
    }
    catch (error) {
        console.log(error);
    }
}
export async function updatePages() {
    mustUpdate('pages').then((needed) => {
        if (needed) {
            localStorage.removeItem('pages');
            staticPages.set(loadPages());
        }
    });
}
export const staticPages = writable(loadPages());
