<script lang="ts">
	import { _ } from 'svelte-i18n';
	import { meta } from 'Utils/meta/store';
	import { quantityInCart } from 'Utils/cart/store';
	import CartItems from 'Components/widgets/CartItems.svelte';

	meta.set({
		title: $_('page.productsCart'),
		description: '',
		keywords: ''
	});
</script>
<header>
	<h1>{$_('page.productsCart')}</h1>
</header>
{#if $quantityInCart > 0}
	<CartItems />
{:else}
<section>
	<article>
		{$_('cart.yourCartEmpty')}
	</article>
</section>
{/if}