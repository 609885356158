<script lang="ts">
	import { 
		_,
		locale
	} from 'svelte-i18n';
	import { isNetworkError } from 'Utils/general/utils';
	import { Link } from 'svelte-navigator';
	import isMobile from 'Utils/general/deviceDetector';
	import Item from './Item.svelte';
	import type { OneProductPartial } from 'Utils/products';

	export let products: Promise<OneProductPartial[]>;
	const itemsLoading: number = isMobile ? 4 : 5;
</script>

{#await products}
	<article class="items">
		{#each Array(itemsLoading) as _}
			<Item />
		{/each}
	</article>
{:then products}
	{#if products && products.length > 0}
		<article class="items">
			{#each products as product}
				<Link to="/{$locale}/product/{product.link}">
					<Item {product} />
				</Link>
			{/each}
		</article>
	{:else}
		<article>
			<span>{$_('shop.noitems')}</span>
		</article>
	{/if}
{:catch error}
	<p style="color: #EB0000;">
		<span>{isNetworkError(error) ? $_('noConnection') : $_('shop.errorLoadingProducts')}</span>
	</p>
{/await}

<style lang="scss">
    @import '../../styles/mixins';

    .items {
		display: grid;
		grid-gap: 10px;
		grid-template-columns: repeat(2, 1fr);
		
		@include desktop {
			grid-gap: 18px;
			grid-template-columns: repeat(5, 1fr);
		}
	}
</style>