<script lang="ts">
import { toMoney } from 'Utils/general/utils';
import Image from 'Components/primitives/Image.svelte';
import type { OneProductPartial } from 'Utils/products';

export let product: OneProductPartial | undefined = undefined;
</script>

<div class="item">
    {#if product}
        {#if product.mainImageThumbUrl}
            <Image src={product.mainImageThumbUrl} alt={product.name} />
        {/if}
        <span class="name">
            { product.name }
        </span>
        <div class="price">
            {#if product.specialPrice}
                <span class="special-price">
                    &euro; {toMoney(product.specialPrice)}
                </span>
            {/if}
            <span class="{product.specialPrice ? 'crossed' : ''}">
                &euro; {toMoney(product.price)}
            </span>
        </div>
    {:else}
        <div class="image placeholder" />
        <span class="name placeholder" />
        <div class="price">
            <span class="specialPrice placeholder" />
            <span class="placeholder" />
        </div>
    {/if}
</div>

<style lang="scss">
    @import '../../styles/mixins';

    .item {
        background-color: white;
        display: grid;
        height: 270px;
        border-radius: 10px;
        transition: all .2s ease-in-out;
        overflow: hidden;
        cursor: pointer;
        grid-template-rows: 80% 1fr 1fr;
        align-items: center;
        text-align: center;

        @include desktop {
            min-width: 200px;
        }

        .image {
            width: 100%;
            height: 220px;
            background-color: #fff;
            object-fit: cover;
        }

        .name {
            font-weight: 500;
            white-space: nowrap;
            text-overflow: ellipsis;
            padding: 0 10px;
            overflow: hidden;
        }

        .price {
            display: flex;
            gap: 10px;
            justify-content: center;

            .crossed {
                text-decoration: line-through;
            }

            .special-price {
                font-weight: 600;
                color: #EB0000;
            }
        }
        
        @include desktop {
            > :global(img) {
                padding: 4px;
            }
            
            &:hover {
                transform: scale(1.03);
                box-shadow: 0 0 20px 1px rgb(0 0 0 / 10%);
            }
        }
    }
</style>