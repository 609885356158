<script lang="ts">
	import { _ } from 'svelte-i18n';
	import { meta } from 'Utils/meta/store';
	
	meta.set({
		title: $_('page.404'),
		description: '',
		keywords: ''
	});
</script>
<header>
	<h1>{$_('page.404')}</h1>
</header>
<section>
	<article>
		{$_('other.pageNotExists')}
	</article>
</section>