<script lang="ts">
	export let label: string;
	export let errors: string[] = [];
	export let checked = false;
</script>

<input class="inp-cbx" id="cbx" type="checkbox" bind:checked={checked} on:change style="display: none" />
<label class="cbx" for="cbx">
	<span class:errorBorder={errors.length > 0}>
		<svg viewBox="0 0 12 9">
			<polyline points="1 5 4 8 11 1" />
		</svg>
	</span>
	<span>{label}</span>
</label>
{#if errors.length > 0}
	{#each errors as error}
		<span class="error-text">{error}</span>
	{/each} 
{/if}

<style lang="scss">
	.error-text {
		color: #EB0000;
		margin: 4px 0 0 10px;
		display: block;
	}

	.errorBorder {
        border: 1px solid red !important;
    }

	.cbx {
		-webkit-user-select: none;
		user-select: none;
		-webkit-tap-highlight-color: transparent;
		cursor: pointer;

		span {
			display: inline-block;
			vertical-align: middle;
			transform: translate3d(0, 0, 0);

			&:first-child {
				position: relative;
				width: 24px;
				height: 24px;
				border-radius: 50%;
				transform: scale(1);
				vertical-align: middle;
				border: 1px solid #B9B8C3;
				transition: all 0.2s ease;

				&:before {
					content: "";
					width: 100%;
					height: 100%;
					background: #dc143c;
					display: block;
					transform: scale(0);
					opacity: 1;
					border-radius: 50%;
					transition-delay: 0.2s;
				}

				svg {
					position: absolute;
					width: 12px;
					height: 9px;
					z-index: 1;
					top: 7px;
					left: 5px;
					fill: none;
					stroke: white;
					stroke-width: 2;
					stroke-linecap: round;
					stroke-linejoin: round;
					stroke-dasharray: 16px;
					stroke-dashoffset: 16px;
					transition: all 0.3s ease;
					transition-delay: 0.1s;
					transform: translate3d(0, 0, 0);
				}
			}

			&:last-child {
				margin-left: 8px;
			}
		}

		&:hover {
			span:first-child {
				border-color: #dc143c;
			}
		}
	}

	.inp-cbx:checked + .cbx span {

		&:first-child {
			border-color: #dc143c;
			background: #dc143c;
			animation: check 0.6s ease;

			svg {
				stroke-dashoffset: 0;
			}

			&:before {
				transform: scale(2.2);
				opacity: 0;
				transition: all 0.6s ease;
			}
		}
	}

	@keyframes check {
		50% {
			transform: scale(1.2);
		}
	}
</style>