<script lang="ts">
    import { navigate } from 'svelte-navigator';

    export let itemsTotal = 0;
    export let itemsPerPage = 20;
    export let currentPage = 1;

    let pagesTotal = Math.ceil(itemsTotal/itemsPerPage);

    $: pageButtons = (() => {
    	if (pagesTotal > 15) {
    		if (currentPage < 7) {
    			return [
    				...Array.from({length: 7}, (_, i) => i + 1),
    				-1,
    				pagesTotal
    			];
    		}

    		if (currentPage > 6 && currentPage < pagesTotal - 4) {
    			return [
    				1,
    				-1,
    				...Array.from({length: 9}, (_, i) => currentPage - 5 + i + 1),
    				-1,
    				pagesTotal
    			];
    		}

    		if (currentPage > pagesTotal - 6) {
    			return [
    				1,
    				-1,
    				...Array.from({length: 7}, (_, i) => pagesTotal - 7 + i + 1)
    			];
    		}
    	}
        
    	return [...Array.from({length: pagesTotal}, (_, i) => i + 1)];
    })();
</script>

{#if pagesTotal > 1}
    <div class="pagination">
        {#each pageButtons as pageNumber}
            {#if pageNumber !== -1}
            <span 
                class:active="{currentPage === pageNumber}"
                on:click={() => {
                    navigate(`?page=${pageNumber}`); currentPage = pageNumber;
                }}
            >
                {pageNumber}
            </span>
            {:else}
                <span class="dots">...</span>
            {/if}
        {/each}
    </div>
{/if}

<style lang="scss">
	.pagination {
        margin-top: 20px;
        display: flex;
        gap: 8px;
        justify-content: center;

        span {
            cursor: pointer;
            padding: 10px;
            border-radius: 4px;
            transition: background .2s ease-in-out;
            background-color: #e1e1e1;

            &.dots {
                padding: 10px 20px;
                cursor: default;
            }
        }

        :hover:not(.active):not(.dots) {
            background-color: #{darken(#e1e1e1, 5%)};
        }

        .active {
            color: #fff;
            background-color: #dc143c;
        }
    }
</style>