<script lang="ts">
    import { _ } from 'svelte-i18n';
    import { categories } from 'Utils/categories/store';
    import {
    	getNewProducts,
    	getSpecialProducts
    } from 'utils/products';
    import { meta } from 'Utils/meta/store';
    import isMobile from 'Utils/general/deviceDetector';
    import Loader from 'Components/primitives/Loader.svelte';
    import ProductsGrid from 'Components/widgets/ProductsGrid.svelte';
    import SearchBar from 'Components/widgets/SearchBar.svelte';
    import ShopCategories from 'Components/widgets/ShopCategories.svelte';
    
    let searchInput: string;
    let newProducts = getNewProducts(isMobile ? 4 : 5);
    let specialProducts = getSpecialProducts(isMobile ? 4 : 10);

    meta.set({
    	title: $_('page.equipment'),
    	description: '',
    	keywords: ''
    });

</script>
<header>
    <h1>{$_('page.equipment')}</h1>
    <SearchBar bind:value={searchInput}/>
</header>
{#await $categories}
    <Loader />
{:then categories}
    <ShopCategories categories={categories} root={true} />
{/await}
<section>
    <header>
        <h2>{$_('shop.newItems')}</h2>
    </header>
    <ProductsGrid products={newProducts} />
</section>
<section>
    <header>
        <h2>{$_('shop.specialOffers')}</h2>
    </header>
    <ProductsGrid products={specialProducts} />
</section>