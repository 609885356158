<script lang="ts">
	import { isLoading } from 'svelte-i18n';
	import { 
		meta,
		serializeSchema
	} from 'Utils/meta/store';
	import Notifications from 'Components/widgets/Notifications.svelte';
	import Router from './Router.svelte';

</script>
<svelte:head>
	<title>{$meta.title}</title>
	<!-- svelte-ignore component-name-lowercase -->
	<meta name="description" content={$meta.description} />
	<!-- svelte-ignore component-name-lowercase -->
	<meta name="keywords" content={$meta.keywords} />
	{#if $meta.schema}
		{@html serializeSchema($meta.schema)}
	{/if}
</svelte:head>
{#if !$isLoading}
	<Notifications>
		<Router />
	</Notifications>
{/if}
<noscript>
	Enable javascript to use this page.
</noscript>
<style lang="scss">
	@import 'styles/mixins';
	// #f30000 - red
	// #c9c9c9 - innactive menu link
	// #494949 - default font colour
	$main-color: #dc143c;

	:global(input:-webkit-autofill),
	:global(input:-webkit-autofill:hover),
	:global(input:-webkit-autofill:focus)
	:global(input:-webkit-autofill),
	:global(textarea:-webkit-autofill),
	:global(textarea:-webkit-autofill:hover)
	:global(textarea:-webkit-autofill:focus),
	:global(select:-webkit-autofill),
	:global(select:-webkit-autofill:hover),
	:global(select:-webkit-autofill:focus) {
		-webkit-text-fill-color: inherit;
		box-shadow: 0 0 0px 1000px transparent inset;
		-webkit-box-shadow: 0 0 0px 1000px transparent inset;
		transition: background-color 5000s ease-in-out 0s;
		background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(0,174,255,0.04) 50%,rgba(255,255,255,0) 51%,rgba(0,174,255,0.03) 100%);
	}

	:global(*) {
		box-sizing: border-box;
		border-width: 0;
		border-style: solid;
		border-image: none;
		margin: 0;
		padding: 0;
		text-decoration: none;
		text-rendering: geometricPrecision;
		outline: none;
		outline-style: none;
		position: relative;
		font-family: 'Roboto';
	}

	:global(.placeholder) {
		background: linear-gradient(90deg, #e1e1e1 20%, #f1f1f1 50%, #e1e1e1 80%);
		animation: loading 1.8s ease infinite;
		background-size: 600% 100%;
	}


	:global(body) {
		display: flex;
		flex-direction: column;
		background-color: #f1f1f1;
		color: #494949;
		min-height: 100vh;
		width: 100vw;
		padding-bottom: calc(54px + env(safe-area-inset-bottom));
		padding-top: 50px;

		@include desktop {
			width: 100%;
			padding-top: 64px;
			padding-bottom: 0px;
		}
	}

	:global(main) {
		flex: 1;
		gap:10px;
	}

	:global(header) {
		display: flex;
		flex-direction: column;
		gap: 8px;

		@include desktop {
			justify-content: space-between;
			flex-direction: row;
			align-items: stretch;
			gap: 0;
		}
	}

	@include desktop {
		:global(article) {
			padding: 0 18px;
		}
	}

	:global(p) {
		margin: 1em 0;
	}

	:global(a) {
		color: #494949;
	}

	:global(h1) {
		font-size: 24px;
		
		@include desktop {
			font-size: 32px;
		}
	}

	:global(h2) {
		font-size: 22px;
    	padding-bottom: 14px;

		@include desktop {
			font-size: 26px;
			padding-bottom: 22px;
		}
	}
	
	:global(svg) {
		width: 100%;
		height: 100%;
	}

	@include mobile {
		:root {
			width: 100vw;
		}
	}

	@keyframes loading {
		0%{
			background-position: 0% 50%;
		}
		100%{
			background-position: 100% 50%;
		}
	}
</style>