<script lang="ts">
	import { locale } from 'svelte-i18n';
	import type { Language } from 'Utils/general/types';
	import type { Page } from 'Utils/general/pages';

	export let data: Page;
	$: currentLang = $locale as Language;
</script>

<header>
	<h1>
		{data.name[currentLang]}
	</h1>
</header>
<section>
	<article>
		{@html data.content[currentLang]}
	</article>
</section>