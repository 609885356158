<script lang="ts">
	export let label: string;
	export let errors: string[] = [];
	export let userSelected: string;
	export let data: {
		value: string,
		label: string
	}[];
</script>
<div class="radio">
	<span>{label}</span>
	{#each data as input}	
		<label>
			<input 
				class:errorBorder={errors.length > 0}
				type="radio"
				bind:group={userSelected}
				value={input.value}
				on:change
			/>
			<span>
				{input.label}
			</span>
		</label>
	{/each}
</div>
{#if errors.length > 0}
	{#each errors as error}
		<span class="error-text">{error}</span>
	{/each} 
{/if}

<style lang="scss">
    @import '../../styles/mixins';
	
	.radio {
		display: flex;
		flex-direction: column;
		gap: 10px;
		
		label {
			display: flex;
			align-items: center;
			gap: 12px;
			padding-left: 10px;
			cursor: pointer;
			@include desktop {
				padding-left: 20px;
			}
		}
	}

	.errorBorder {
		border: 1px solid red !important;
	}

	.error-text {
		color: #EB0000;
		margin: 4px 0 0 10px;
		display: block;
	}

	input[type="radio"] {
		-webkit-appearance: none;
		appearance: none;
		margin: 0;
		width: 24px;
		height: 24px;
		border: 1px solid #B9B8C3;
		border-radius: 50%;
		transform: translateY(-0.075em);
		display: grid;
		place-content: center;
		
		&:hover {
			border: 1px solid #dc143c;
		}

		&::before {
			content: "";
			width: 12px;
			height: 12px;
			border-radius: 50%;
			transform: scale(0);
			transition: .120s transform ease-in-out;
			background-color: #dc143c;
		}

		&:checked::before {
			transform: scale(1);
		}

		&:focus,
		&:checked {
			border: 1px solid #dc143c;
		}
	}
</style>