import { mustUpdate } from 'Utils/general/updater';
import { writable } from 'svelte/store';
async function loadCategories() {
    const cachedCategories = localStorage.getItem('categories');
    if (cachedCategories) {
        return JSON.parse(cachedCategories);
    }
    try {
        const response = await fetch('/api/shop/categories/tree');
        const categories = await response.json();
        if (response.ok) {
            localStorage.setItem('categories', JSON.stringify(categories.message));
            return categories.message;
        }
        throw new Error('Error');
    }
    catch (error) {
        console.log(error);
    }
}
export async function updateCategories() {
    mustUpdate('categories').then((needed) => {
        if (needed) {
            localStorage.removeItem('categories');
            categories.set(loadCategories());
        }
    });
}
export const categories = writable(loadCategories());
export const filters = writable({
    categoryId: NaN,
    filterArray: {},
    query: ''
});
