<script lang="ts">
	import FacebookSVG from '../../icons/social/fb.svg';
	import TelegramSVG from '../../icons/social/telegram.svg';
	import TwitterSVG from '../../icons/social/twitter.svg';
	import WhatsappSVG from '../../icons/social/whatsapp.svg';
	export let url: string;

</script>

	<div class="share">
		<a href={`https://www.facebook.com/sharer.php?u=${url}`} target="_blank">
			<FacebookSVG />
		</a>
		<a href={`https://twitter.com/intent/tweet?text=${url}`} target="_blank">
			<TwitterSVG />
		</a>
		<a href={`https://wa.me/?text=${url}`} target="_blank">
			<WhatsappSVG />
		</a>
		<a href={`https://telegram.me/share/url?url=${url}`} target="_blank">
			<TelegramSVG />
		</a>
	</div>

<style lang="scss">
	.share {
		display: flex;
		gap: 15px;
		flex-direction: row;
		justify-content: center;

		> a {
			height: 56px;
			width: 56px;
		}
	}
</style>