<script lang="ts">
import { cubicOut } from 'svelte/easing';
import { 
	fade,
	fly
} from 'svelte/transition';
import { scrollLocker } from 'Utils/general/utils';
import {
	Tweened,
	tweened
} from 'svelte/motion';
import ArrowLeftSVG from '../../icons/arrow-left.svg';
import ArrowRightSVG from '../../icons/arrow-right.svg';
import CloseSVG from '../../icons/xlg.svg';
import isMobile from 'Utils/general/deviceDetector';
import type { OneImage } from 'Utils/products';

function scaleOut(node: Node, options: object) {
	return {
		duration: 200,
		css: (t: number, u: number) => `
			opacity: ${t};
			transform: scale(${t});
		`
	};
}

export let images: OneImage[];
export let ifOpened = false;
export let selectedInitial = 0;
let totalImages = images.length;
let currentScale = 1;
let initialX = 0;
let initialY = 0;

let draggedHorizontally: Tweened<number> = tweened(0, {
	duration: 200,
	easing: cubicOut
});

let draggedVertically : Tweened<number> = tweened(0, {
	duration: 200,
	easing: cubicOut
});

let moved: Tweened<number> = tweened(0, {
	duration: 200,
	easing: cubicOut
});

function close() {
	currentScale = 1;
	ifOpened = false;
	$moved = 0;
}

function next() {
	const stopedInteger = Math.round($moved);
	if(stopedInteger + selectedInitial + 1 < totalImages) {
		currentScale = 1;
		$moved = stopedInteger + 1;
	}
}

function previous() {
	const stopedInteger = Math.round($moved);
	if(stopedInteger + selectedInitial > 0) {
		currentScale = 1;
		$moved = stopedInteger - 1;
	}
}

const handleKeydown = (e: KeyboardEvent) => {
	if (e.key === 'Escape' as string) {
		close();
		return;
	}

	if (e.key === 'ArrowLeft' as string) {
		previous();
		return;
	}

	if (e.key === 'ArrowRight' as string) {
		next();
		return;
	}
};

const handleWheel = (e: WheelEvent) => {
	if (currentScale <= 1 && e.deltaY < 0) {
		return;
	}

	if (currentScale >= 1.4 && e.deltaY > 0) {
		return;
	}

	currentScale += e.deltaY/1000 * 2;
};

function clickOutside(e: Event) {
	const clickedElement = e.target as Node;
	if (clickedElement && clickedElement.nodeName.toLowerCase() === 'img') {
		return;
	}

	close();
}

function handleTouchStart(e: TouchEvent) {
	if(e.touches.length === 1) {
		initialX = e.changedTouches[0].screenX;
		initialY = e.changedTouches[0].screenY;
	}
}

function handleTouchMove(e: TouchEvent) {
	let movedHorizontally = Math.abs(e.changedTouches[0].screenX - initialX);
	let movedVertically = Math.abs(e.changedTouches[0].screenY - initialY);

	if(e.touches.length === 1 && movedHorizontally > movedVertically) {
		$draggedHorizontally = e.changedTouches[0].screenX - initialX;
	}

	if(e.touches.length === 1 && movedHorizontally < movedVertically) {
		$draggedVertically = e.changedTouches[0].screenY - initialY;
	}
}

function handleTouchEnd(e: TouchEvent) {

	if($draggedHorizontally > 50) {
		previous();
	} 
	
	if ($draggedHorizontally < -50) {
		next();
	}

	if ($draggedVertically > 100 || $draggedVertically < -100) {
		close();
	}

	$draggedHorizontally = 0;
	$draggedVertically = 0;
}

$: scrollLocker(ifOpened);

</script>

<svelte:window on:keydown={handleKeydown} />
{#if ifOpened}
	<div class="box" transition:fade={{duration: 200}} on:click={clickOutside}>
		<div class="slider" style="transform: translate(calc(-{(selectedInitial + $moved) * 100}% + {$draggedHorizontally}px), {$draggedVertically}px);">
			{#each images as photo, i }
				<div
					class="slide"
					on:touchstart|passive={handleTouchStart}
					on:touchmove|passive={handleTouchMove}
					on:touchend|passive={handleTouchEnd}
				>
					<img
						in:scaleOut
						out:fly={{y: 500, duration: 200, opacity: 0, easing: cubicOut}}
						alt="wow"
						src={photo.thumbs[photo.thumbs.length - 1].url}
						style="transform: scale({currentScale})"
						on:wheel={handleWheel}
					/>
				</div>
			{/each}
		</div>
		<div class="close" on:click={close}>
			<CloseSVG />
		</div>
		{#if $moved + selectedInitial > 0 && !isMobile}
			<div class="left" on:click|stopPropagation|preventDefault={previous}>
				<ArrowLeftSVG width="56px" height="56px" />
			</div>
		{/if}
		{#if $moved + selectedInitial < images.length - 1 && !isMobile}
			<div class="right" on:click|stopPropagation|preventDefault={next}>
				<ArrowRightSVG width="56px" height="56px" />
			</div>
		{/if}
	</div>
{/if}
	
<style lang="scss">
    @import '../../styles/mixins';
	
	.box {
		display: flex;
		position: fixed;
		background: rgba(0, 0, 0, 0.68);
		backdrop-filter: blur(16px);
		-webkit-backdrop-filter: blur(16px);
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		z-index: 99;
		will-change: opacity;
		user-select: none;
		-webkit-user-select: none;
		-moz-user-select: none;
		-khtml-user-select: none;
		-ms-user-select: none;

		.slider {
			width: 100%;
			display: flex;
			will-change: transform;

			.slide {
				width: 100vw;
				height: 100vh;
				height: -webkit-fill-available;
				flex: none;
				display: flex;
				justify-content: center;
				align-items: center;
	
				img {
					background-color: #fff;
					transition: transform .3s ease;
					will-change: transform, opacity;
					max-width: 100%;
					max-height: 100%;
	
					@include desktop {
						max-width: 70%;
						max-height: 70%;
					}
				}
			}
		}

		.close {
			display: block;
			position: fixed;
			top: 0;
			right: 0;
			width: 54px;
			height: 54px;
			padding: 10px;
			opacity: .5;
			transition: opacity .3s ease-in-out;
			cursor: pointer;

			@include desktop {
				top: 20px;
				right: 20px;
				width: 66px;
				height: 66px;
			}

			&:hover {
				opacity: .7;
			}

			:global(svg) {
				color: #fff;
			}
		}

		.left,
		.right {
			display: flex;
			align-items: center;
			position: fixed;
			top: 50%;
			width: 20%;
			height: 60%;
			transform: translateY(-50%);
			opacity: .1;
			transition: opacity .3s ease-in-out;
			cursor: pointer;

			&:hover {
				opacity: .7;
			}

			:global(svg) {
				width: 56px;
				height: 56px;
				color: #fff;
			}
		}
		
		.left {
			left: 0;
			padding-left: 20px;
		}

		.right {
			right: 0;
			padding-right: 20px;
			justify-content: end;
		}
	}
</style>