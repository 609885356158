<script lang="ts">
import { 
	_,
	locale
} from 'svelte-i18n';
import { 
	getProductsBySearch,
	OneProductPartial
} from 'Utils/products';
import { isNetworkError } from 'Utils/general/utils';
import { link } from 'svelte-navigator';
import Image from 'Components/primitives/Image.svelte';
import Loader from 'Components/primitives/Loader.svelte';
import SearchSVG from '../../icons/search.svg';

export let value: string;
export let placeholder = $_('shop.startTyping');
let products: OneProductPartial[] | undefined = undefined;

$: (async() => {
	if(value) {products = await getProductsBySearch(value);}
})();

</script>

<div class="input" tabindex="0">
    <input 
        class="field"
        bind:value={value} 
        placeholder={placeholder}
        on:input
    />
    <div class="icon">
        <SearchSVG />
    </div>
    <div class="results">
        {#await products}
            <Loader />
        {:then products}
            {#if products && value}
                {#if products.length > 0}
                    {#each products as product}
                        <a href="/{$locale}/product/{product.link}" use:link>
                            <Image src={product.mainImageThumbUrl} alt={product.name} />
                            <span>{product.name}</span>
                        </a>
                    {/each}
                {:else}
                    <span>{$_('shop.noSearchedItems')}</span>
                {/if}
            {/if}
        {:catch error}
            <span>{isNetworkError(error) ? $_('noConnection') : $_('shop.errorLoadingProducts')}</span>
        {/await}
    </div>
</div>

<style lang="scss">
	@import '../../styles/mixins';
    
    .input {
        width: 100%;
        transition: all .2s ease-in-out;

        @include desktop {
            width: 340px;
        }
        
        &:focus-within .icon {
            opacity: 1;

           & + .results { 
                display: block;
            }
        }
    }
    
    .field {
        background-color: #e1e1e1;
        border-radius: 5px;
        font-size: 16px;
        padding: 8px 12px;
        width: 100%;
        height: 38px;

        &:focus {
            box-shadow: 0 0 0 2px #dc143c;
            border-radius: 5px;
            background-color: #fff;
        }

        &:hover:not(:focus) {
            background-color: #e9e9e9;
        }
    }

    .icon {
        position: absolute;
        top: 8px;
        right: 10px;
        width: 22px;
        opacity: .6;
    }

    .results {
        display: none;
        position: absolute;
        width: 100%;
        margin-top: 10px;
        background-color: white;
        border-radius: 5px;
        z-index: 97;
        box-shadow: 0 0 20px 1px rgb(0 0 0 / 10%);

        > span {
            display: block;
            padding: 10px;
        }

        > a {
            display: grid;
            grid-template-columns: min-content 1fr;
            padding: 10px;
            cursor: pointer;
            gap: 10px;
            align-items: center;

            > :global(img),
            > :global(div) {
                width: 48px;
                height: 48px;
                object-fit: cover;
            }

            &:hover {
                background-color: #fde6d5;
            }
        }
    }
</style>