<script lang="ts">
	import { categories } from 'Utils/categories/store';
	import {locale} from 'svelte-i18n';
	import {
		Route,
		Router
	} from 'svelte-navigator';
	import Cart from 'Components/routes/Cart.svelte';
	import ContentWrapper from 'Components/layouts/ContentWrapper.svelte';
	import Footer from 'Components/widgets/Footer.svelte';
	import Header from 'Components/widgets/Header.svelte';
	import HowToFindUs from 'Components/routes/HowToFindUs.svelte';
	import Loader from 'Components/primitives/Loader.svelte';
	import NotFound from 'Components/routes/NotFound.svelte';
	import Order from 'Components/routes/Order.svelte';
	import OrderSuccess from 'Components/routes/OrderSuccess.svelte';
	import PageRouter from 'Components/routes/PageRouter.svelte';
	import Shop from 'Components/routes/Shop.svelte';
	import ShopCategoryRouter from 'Components/routes/ShopCategoryRouter.svelte';
	import ShopItem from 'Components/routes/ShopItem.svelte';
</script>
<Router>
	<Header />
	<main>
		<ContentWrapper>
			<div class="content">
				<Route path="/{$locale}/*" primary={false}>
					<Route path="/" component="{Shop}" />
					<Route path="/shop/*">
						{#await $categories}
							<Loader />
						{:then category} 
							{#if category}
								<ShopCategoryRouter category={category} isRoot={true} />
							{/if}
						{/await}
						<Route component="{NotFound}" />
					</Route>
					<Route path="/product/:slug" component={ShopItem} />
					<Route path="/howtofindus" component={HowToFindUs} />
					<Route path="/cart" component={Cart} />
					<Route path="/order/*">
						<Route exact path="/" component={Order} />
						<Route path="/success/:id" component={OrderSuccess} />
					</Route>
					<Route path="*" component={PageRouter} />
				</Route>
			</div>
		</ContentWrapper>
	</main>
	<Footer />
</Router>

<style lang="scss">
	@import 'styles/mixins';

	.content {
		display: flex;
		flex-direction: column;
		gap: 14px;
		width: 100%;
		height: auto;
		padding: 14px;

		@include desktop {
			gap: 20px;
		}
	}
</style>