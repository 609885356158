<script lang="ts">
	import { _ } from 'svelte-i18n';
	
	export let label: string;
	export let errors: string[] = [];
	export let value: string;
	export let maxLength: number | undefined = undefined;
	
	</script>
	
	<div class="textarea">
		<span class="label">{label}</span>
		<label>
			<textarea class="field"
				{maxLength}
				bind:value={value}
				on:input
				class:error={errors.length > 0}
			/>
			{#if maxLength}
				<span class="characters">{value.length}/{maxLength}</span>
			{/if}
			{#if errors.length > 0}
				{#each errors as error}
					<span class="error-text">{error}</span>
				{/each} 
			{/if}
		</label>
	</div>
	
	<style lang="scss">
		@import '../../styles/mixins';

		.textarea {
			display: grid;
			align-items: center;
			grid-gap: 6px;
			
			@include desktop {
				grid-template-columns: 1fr 300px;
			}
		}

		.characters {
			position: absolute;
			right: 5px;
			bottom: 7px;
			font-size: 13px;
			color: #b2b8bf;
		}
	
		.error-text {
			color: #EB0000;
			margin: 4px 0 0 10px;
			display: block;
		}
	
		.error {
			border: 1px solid red;
		}
		
		.field {
			resize: none;
			background-color: #fff;
			border-radius: 5px;
			border: 1px solid #d8dbdf;
			font-size: 16px;
			padding: 8px 12px;
			width: 100%;
			height: 84px;
			transition: all .2s ease-in-out;
	
			&:focus {
				border: 1px solid #dc143c;
			}
	
			&:hover:not(:focus) {
				border: 1px solid #b2b8bf;
			}
		}
	</style>