import { get, writable } from 'svelte/store';
import { query } from './utils';
export const versions = writable(getVersions());
async function getLocalVersion(key) {
    return get(versions).then((storeVersions) => {
        var _a;
        return (_a = storeVersions[key]) !== null && _a !== void 0 ? _a : undefined;
    });
}
async function getVersions() {
    const encoded = localStorage.getItem('versions');
    if (!encoded) {
        return query('/api/general/versions').then((serverVersions) => {
            localStorage.setItem('versions', JSON.stringify(serverVersions));
            return serverVersions;
        });
    }
    return JSON.parse(encoded);
}
export function updateLocalVersion(key, datetime) {
    const local = JSON.parse(localStorage.getItem('versions'));
    local[key] = datetime;
    versions.update(async (promise) => {
        const state = await promise;
        return Object.assign(Object.assign({}, state), { [key]: datetime });
    });
    localStorage.setItem('versions', JSON.stringify(local));
}
export async function mustUpdate(key) {
    return query(`/api/general/versions/key/${key}`).then(async (serverVersion) => {
        if (serverVersion !== await getLocalVersion(key)) {
            updateLocalVersion(key, serverVersion);
            return true;
        }
        return false;
    });
}
