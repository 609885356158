<script lang="ts">
	import ButtonLoaderSVG from '../../icons/button-loader.svg';
	type Button = 'standart' | 'small' | 'white';
	export let value: string;
	export let disabled = false;
	export let type: Button = 'standart';
	export let isLoading = false;
</script>

<button
	class={type}
	class:disabled={disabled}
	on:click
	{disabled}
>
	{#if isLoading}
		<ButtonLoaderSVG />
	{/if}
	<slot name="before" />
	{value}
</button>

<style lang="scss">
    @import '../../styles/mixins';

	button {
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 4px;
		height: 42px;
		gap: 12px;
		font-size: 16px;
		font-weight: 500;
		color: var(--textColor);
		background-color: var(--backgroundColor);
		transition: background .2s;
		padding: 8px 12px;
		cursor: pointer;

		:global(svg) {
			width: 24px;
			height: 24px;
		}

		&:hover:not(.disabled) {
			background: var(--hoverColor);
		}
	}

	.white {
		--backgroundColor: #fff;
		--hoverColor: #{darken(#fff, 5%)};
		--textColor: #494949;
		border: 1px solid #d8dbdf;
		font-weight: 400;

		@include desktop {
			&:hover:not(.disabled) {
				background-color: #fff;
				border: 1px solid #b2b8bf;
			}
		}
	}
	
	.standart {
		--backgroundColor: #dc143c;
		--hoverColor: #{darken(#dc143c, 5%)};
		--textColor: #fff;
		padding: 8px 12px;
	}

	.small {
		--backgroundColor: #fff;
		--textColor: #494949;
		--hoverColor: #{darken(#fff, 5%)};
		padding: 4px 10px;
		border: 1px solid #e1e1e1;
		width: 30px;
		height: 30px;
		text-align: center;
	}

	.disabled {
		cursor: default;
		background-color: #fff;
		color: #e1e1e1;
	}
</style>