<script lang="ts">
	import { _ } from 'svelte-i18n';
	import { toMoney } from 'Utils/general/utils';
	export let total: number;
	export let deliveryPrice = 0;
</script>

<div class="summary">
	<div class="calculations">
		<span>{$_('cartSummary.sum')}</span>
		<span>&euro; {toMoney(total/121*100)}</span>
		<span>{$_('cartSummary.pvn')}</span>
		<span>&euro; {toMoney(total/121*21)}</span>
		{#if deliveryPrice > 0}
			<span>{$_('cartSummary.delivery')}</span>
			<span>&euro; {toMoney(deliveryPrice)}</span>
		{/if}
	</div>
	<div class="total">
		<span>{$_('cartSummary.total')}</span>
		<span>&euro; {toMoney(total + deliveryPrice)}</span>
	</div>
	<slot />
</div>

<style lang="scss">
			.summary {
			display: grid;
			grid-gap: 20px;
			align-self: start;

			.calculations,
			.total {
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				
				span:nth-child(2n) {
					text-align: right;
					font-weight: 600;
				}
			}

			.calculations {
				border-bottom: 1px solid #e1e1e1;
				padding-bottom: 10px;
				grid-gap: 8px;
			}

			.total {
				font-size: 20px;
				font-weight: 600;
			}
		}
</style>