<script lang="ts">
    import {
    	_,
    	locale
    } from 'svelte-i18n';
    import { filters } from 'Utils/categories/store';
    import {
    	getCategoryProducts,
    	getProductQuantity,
    	OneProductPartial
    } from 'Utils/products';
    import { meta } from 'Utils/meta/store';
    import {
    	navigate,
    	useLocation
    } from 'svelte-navigator';
    import Button from 'Components/primitives/Button.svelte';
    import Filters from 'Components/widgets/Filters.svelte';
    import FunnelSVG from '../../icons/funnel.svg';
    import Paginator from 'Components/widgets/Paginator.svelte';
    import ProductsGrid from 'Components/widgets/ProductsGrid.svelte';
    import Select, { Option } from 'Components/primitives/Select.svelte';
    import ShopBreadCrumbs from 'Components/widgets/ShopBreadCrumbs.svelte';
    import ShopCategories from 'Components/widgets/ShopCategories.svelte';
    import SortSVG from '../../icons/arrow-down-up.svg';
    import type { Category } from 'Utils/categories/types';
    import type { Language } from 'Utils/general/types';
    
    export let category: Category;

    let currentLang = $locale as Language;
    $: currentLang = $locale as Language;

    const itemsPerPage = 20;
    const location = useLocation();
    
    let currentPage = $location.search ? Number($location.search.slice(6)) : 1;
    $: currentPage = $location.search ? Number($location.search.slice(6)) : 1;

    if ($filters.categoryId !== category.id) {
    	filters.set({
    		categoryId: category.id,
    		filterArray: {},
    		query: ''
    	});
    }

    let orderItems: Option[] = [
    	{value: 'id,desc', label: $_('newFirst')},
    	{value: 'id,asc', label: $_('oldFirst')},
    	{value: 'allPrices,asc', label: $_('cheapFirst')},
    	{value: 'allPrices,desc', label: $_('expensiveFirst')}
    ];
    
    let selectedItem = 'id,desc';
    let openFilters = false;

    let itemsTotal = getProductQuantity(category.id, $filters.query);
    let products: Promise<OneProductPartial[]> = getCategoryProducts(
    	category.id,
    	(currentPage - 1) * itemsPerPage,
    	itemsPerPage,
    	selectedItem,
    	$filters.query
    );

    $: {
    	itemsTotal = getProductQuantity(category.id, $filters.query);
    }

    $: if (currentPage) {
    	products = getCategoryProducts(
    		category.id,
    		(currentPage - 1) * itemsPerPage,
    		itemsPerPage,
    		selectedItem,
    		$filters.query
    	);
    }

    meta.set({
    	title: category.name[currentLang],
    	description: category.description[currentLang].replace(/(<([^>]+)>)/gi, '').slice(0, 150),
    	keywords: category.keywords[currentLang]
    });
</script>

<header>
    <h1>{category.name[currentLang]}</h1>
</header>
    {#if Object.keys(category.children).length > 0}
        <ShopCategories categories={category} />
    {/if}
    <div class="controls">
        <ShopBreadCrumbs />
        <div class="sort">
            <Select
                items={orderItems}
                bind:value={selectedItem}
                isClearable={false}
                allowInput={false}
            >
                <SortSVG slot="before" />
            </Select>
            <Button value="{$_('filtrs')}" type="white" on:click={() => {
                openFilters = !openFilters;
            }}>
                <FunnelSVG slot="before" style={$filters.query !== '' ? 'color:#dc143c;' : ''}/>
            </Button>
        </div>
    </div>
<section>
    <ProductsGrid products={products} />
    {#await itemsTotal then itemsTotal}
        <Paginator itemsPerPage={itemsPerPage} itemsTotal={itemsTotal} bind:currentPage={currentPage} />
    {/await}
</section>
<Filters
    bind:opened={openFilters}
    bind:filtersString={$filters.query}
    attributes={category.attributes}
    on:apply={() => {navigate('?page=1'); currentPage = 1; }}
/>

<style lang="scss">
	@import '../../styles/mixins';
    .controls {
        display: flex;
        flex-direction: column;
        gap: 14px;

        @include after-mobile {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }

        .sort {
            display: grid;
            gap: 10px;
            grid-template-columns: 200px minmax(0,auto);
        }
    }
</style>