<script lang="ts">
	import { onMount } from 'svelte';

	export let src: string;
	export let alt: string;
	export let selected = false;
	let loaded = false;
	let failed = false;
	let loading = false;

	onMount(() => {
		const img = new Image();
		img.src = src;
		loading = true;

		img.onload = () => {
			loading = false;
			loaded = true;
		};
		img.onerror = () => {
			loading = false;
			failed = true;
		};
	});
</script>


{#if loaded}
	<img 
		class="image"
		on:click
		{src}
		{alt}
		class:selected={selected}
	/>
{:else if failed}
	<div class="image placeholder" alt="Not Found {src}" />
{:else if loading}
	<div class="image placeholder" alt="Loading..." />
{/if}

<style lang="scss">
	.image {
		width: 100%;
		height: 100%;
		object-fit: contain;
		// background-color: #fff;
	}

	.selected {
		box-shadow: 0 0 1px 1px #dc143c;
	}
</style>