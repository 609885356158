<script lang="ts" context="module">
	export interface Option {
		value: string,
		label: string,
		icon?: SvelteComponent
	}
</script>
<script lang="ts">
	import { _ } from 'svelte-i18n';
	import { clickOutside } from 'Utils/general/clickOutside';
	import {
		createEventDispatcher,
		SvelteComponent
	} from 'svelte/internal';
	import CrossSVG from '../../icons/xlg.svg';

	const dispatch = createEventDispatcher();

	export let value: string | undefined = undefined;
	export let items: Option[] = [];
	export let hasError = false;
	export let placeholder: string = $_('other.chooseSomething');
	
	export let isClearable = true;
	export let allowInput = true;
	export let openList = false;
	
	let selectedItem: Option | undefined = undefined;
	let sortedItems: Option[] = items;

	function handleSelectClick(e: Event) {
		const target = e.target as HTMLElement;
		
		if (target.tagName === 'LABEL' || target.tagName === 'INPUT') {
			openList = !openList;
		}
	}

	function handleSelect(item: string) {
		sortedItems = items;
		value = item;
		openList = false;
		dispatch('select');
	}

	$: {
		for (const item of items) {
			if (value === undefined) {
				selectedItem = undefined;
				break;
			}
			if (item.value === value) {
				selectedItem = item;
				break;
			}
		}
	}

	function handleClear() {
		dispatch('clear');
		value = undefined;
	}

	function filterOptions(event: Event) {
		const input = event.target as HTMLInputElement;
		sortedItems = items.filter(
			(item => item.label.toLowerCase().includes(input.value.toLowerCase())
			));
	}
</script>

<div class="select">
	<label class:error={hasError} on:click="{handleSelectClick}">
		<slot name="before" />
		<input
			type="text"
			class="field"
			on:input="{filterOptions}"
			value="{selectedItem ? selectedItem.label : ''}"
			{placeholder}
			readonly={!allowInput} 
		>
		{#if selectedItem && isClearable}
			<div class="clear" on:click|preventDefault="{handleClear}">
				<CrossSVG />
			</div>
		{/if}
	</label>
	{#if openList}
		<div class="list" use:clickOutside on:click_outside={() => {openList = false;}}>
			{#if items.length === 0}
				{$_('noData')}
			{/if}
			{#each sortedItems as item}
				<div
					class="item"
					value={item.value}
					class:selected={selectedItem && selectedItem.value === item.value}
					on:click={() => handleSelect(item.value)}
				>
					{item.label}
				</div>
			{/each}
		</div>
	{/if}
</div>


<style lang="scss">

	label {
		display: flex;
		gap: 12px;
		align-items: center;
		justify-content: space-between;
		background-color: #fff;
		border-radius: 5px;
		border: 1px solid #d8dbdf;
		width: 100%;
		height: 42px;
		padding: 8px 12px;

		.field {
			flex: 1;
			width: inherit;
			color: #494949;
			font-size: 16px;
			background: transparent;
		}

		&:focus {
			border: 1px solid #dc143c;
		}

		&:hover:not(:focus):not(.error) {
			border: 1px solid #b2b8bf;
		}

		&.error {
			border: 1px solid #EB0000 !important;
		}

		:global(svg),
		.clear {
			width: 24px;
			height: 24px;
			cursor: pointer;
		}
	}

	.list {
		width: 100%;
		background-color: #fff;
		border-radius: 5px;
		position: absolute;
		top: calc(100% + 8px);
		left: 0;
		z-index: 100;
		box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.1);
		overflow: auto;
		max-height: 500%;

		.item {
			padding: 15px;
			cursor: pointer;

			&.selected {
				color: #fff;
				background-color: #dc143c;
			}

			&:hover:not(.selected) {
				background-color: #{lighten(#dc143c, 50%)};
			}
		}
	}

</style>