<script lang="ts">
    import {
    	_,
    	locale
    } from 'svelte-i18n';
    import { getOneProduct } from 'Utils/products';
    import { meta } from 'Utils/meta/store';
    import { toMoney } from 'Utils/general/utils';
    import { useParams } from 'svelte-navigator';
    import AddToCart from 'Components/widgets/AddToCart.svelte';
    import ItemGallery from 'Components/widgets/ItemGallery.svelte';
    import Loader from 'Components/primitives/Loader.svelte';
    import PopUp from 'Components/widgets/PopUp.svelte';
    import ShareButtons from 'Components/widgets/ShareButtons.svelte';
    import ShareSVG from '../../icons/reply.svg';
    import type { Language } from 'Utils/general/types';

    let params = useParams();
    let ifShareBoxOpened = false;
    const product = getOneProduct($params.slug);
    $: currentLang = $locale as Language;

    product.then(product => {
    	const description = product.description[currentLang].replace(/(<([^>]+)>)/gi, '').slice(0, 150);
        
    	const schema = {
    		'@context': 'https://schema.org',
    		'@type': 'Product',
    		'description': description,
    		'name': product.name,
    		'image': product.mainImage.url,
    		'offers': {
    			'@type': 'Offer',
    			'availability': 'https://schema.org/InStock',
    			'price': product.specialPrice || product.price,
    			'priceCurrency': 'EUR'
    		}
    	};
        
    	meta.set({
    		title: product.name,
    		description: description,
    		keywords: product.keywords[currentLang],
    		schema: schema
    	});
    });
</script>

{#await product}
    <Loader />
{:then product}
    <header>
        <h1>{product.name}</h1>
    </header>
    <section>
        <article>
            <div class="divider">
                <ItemGallery photos={product.images} />
                <div class="content">
                    <div class="top">
                        <div class="price">
                            <span>{$_('shop.price')}: </span>
                            {#if product.specialPrice}
                                <span class="discount">
                                    &euro; {toMoney(product.specialPrice)}
                                </span>
                            {/if}
                            <span class="{product.specialPrice ? 'crossed' : ''}">
                                &euro; {toMoney(product.price)}
                            </span>
                        </div>
                        <div class="control">
                            <div on:click="{() => ifShareBoxOpened = true}">
                                <ShareSVG />
                            </div>
                        </div>
                    </div>
                    <AddToCart {product} />
                    <div class="description">
                        {@html product.description[currentLang]}
                    </div>
                </div>
            </div>
        </article>
        {#if ifShareBoxOpened}
            <PopUp on:close="{() => ifShareBoxOpened = false}">
                <h3 slot="header">{$_('other.shareThis')}</h3>
                <div slot="content">
                    <ShareButtons url={location.href} />
                </div>
            </PopUp>
        {/if}
    </section>
{:catch errors}
    <p style="color: #EB0000">{$_('noConnection')}</p>
{/await}

<style lang="scss">
    @import '../../styles/mixins';

    .divider {
        display: grid;
        grid-gap: 10px;
        
        @include desktop {
            grid-gap: 24px;
            grid-template-columns: repeat(2, 1fr);
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        gap: 10px;
        
        .top {
            display: flex;
            justify-content: space-between;
            
            .price {
                font-size: 24px;
                font-weight: 600;
                display: flex;
                gap: 20px;

                .discount {
                    font-weight: 700;
                    color: #EB0000;
                }

                .crossed {
                    text-decoration: line-through;
                }
            }

            .control > * {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 36px;
                height: 100%;
                padding: 0 4px;
                opacity: 0.6;
                transition: opacity 0.2s ease-in;
                cursor: pointer;
                
                &:hover {
                    opacity: 1;
                }
            }
        }
    }

</style>