<div class="loader" />

<style lang="scss">
	.loader{ 
		margin: 16px auto;
		border: 8px solid #e1e1e1;
		border-top: 8px solid #dc143c;
		border-radius: 50%;
		width: 48px;
		height: 48px;
		animation: spin 1s linear infinite;
	}

	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
</style>