<div class="wrapper">
	<slot></slot>
</div>
<style lang="scss">
	@import '../../styles/mixins';

	.wrapper {
		margin: 0 auto;
		width: 100%;
		max-width: 1260px;
		height: 100%;

		@include desktop {
			padding: 0 20px;
		}
	}
</style>