<script lang="ts">
	import { Route } from 'svelte-navigator';
	import {
		staticPages,
		updatePages
	} from 'Utils/general/pages';
	import Loader from 'Components/primitives/Loader.svelte';
	import NotFound from 'Components/routes/NotFound.svelte';
	import Page from 'Components/widgets/Page.svelte';
	updatePages();
</script>

{#await $staticPages}
	<Loader />
{:then pages}
	{#if pages}
		{#each pages as page}
			<Route path={page.link}>
				<Page data={page} />
			</Route>
		{/each}
	{/if}
	<Route component="{NotFound}" />
{/await}