import { getLocaleFromNavigator, init, locale, register } from 'svelte-i18n';
import { navigate } from 'svelte-navigator';
register('lv', () => import('../../lang/lv.json'));
register('ru', () => import('../../lang/ru.json'));
export function startTranslate() {
    const urlLocale = location.pathname.split('/')[1];
    const selectedLocale = (() => {
        if (urlLocale && urlLocale === 'lv' || urlLocale === 'ru') {
            return urlLocale;
        }
        const savedLocale = localStorage.getItem('lang');
        if (savedLocale) {
            return savedLocale;
        }
        const navigatorLocale = getLocaleFromNavigator();
        const shortNavigatorLocale = navigatorLocale
            ? navigatorLocale.substring(0, 2)
            : null;
        return shortNavigatorLocale
            ? (['lv',
                'ru'].includes(shortNavigatorLocale)
                ? shortNavigatorLocale
                : 'lv')
            : 'lv';
    })();
    init({
        fallbackLocale: 'lv',
        initialLocale: selectedLocale
    });
    if (!urlLocale) {
        navigate(selectedLocale, { replace: true });
    }
    localStorage.setItem('lang', selectedLocale);
}
export function switchLocale(lang) {
    locale.set(lang);
    navigate(location.pathname.replace(location.pathname.split('/')[1], lang), { replace: true });
    localStorage.setItem('lang', lang);
}
