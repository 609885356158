<script lang="ts">
	import {
		_,
		locale
	} from 'svelte-i18n';
	import {
		CartItem,
		clearCart,
		items,
		quantityInCart
	} from 'Utils/cart/store';
	import { createOrder } from 'Utils/cart/api';
	import {
		getContext,
		onMount
	} from 'svelte';
	import { 
		getProductsByIds,
		OneProductFull,
		Variation
	} from 'Utils/products';
	import { meta } from 'Utils/meta/store';
	import { navigate } from 'svelte-navigator';
	import { 
		normalizeData,
		toMoney
	} from 'Utils/general/utils';
	import Button from 'Components/primitives/Button.svelte';
	import CartSummary from 'Components/widgets/CartSummary.svelte';
	import Checkbox from 'Components/primitives/Checkbox.svelte';
	import FormItem from 'Components/primitives/FormItem.svelte';
	import Image from 'Components/primitives/Image.svelte';
	import Input from 'Components/primitives/Input.svelte';
	import Loader from 'Components/primitives/Loader.svelte';
	import Radio from 'Components/primitives/Radio.svelte';
	import SelectTwo from 'Components/primitives/Select.svelte';
	import Textarea from 'Components/primitives/Textarea.svelte';
	import type { AddNotification } from 'Components/widgets/Notifications.svelte';
	import type { Language } from 'Utils/general/types';
	import type { WithID } from 'Utils/request/types';
	
	interface OmnivaJSON {
		[property: string]: string
	}
	
	interface Rule {
		required?: {
			value: boolean,
			errorText: string
		}
		pattern?: {
			value: RegExp,
			errorText: string
		}
	}
	
	type Rules = Partial<Record<FormFields, Rule>>;
	
	interface Errors {
		[property: string]: string[]
	}

	interface OrderValues {
		firstName: string,
		lastName: string,
		email: string,
		phone: string,
		comments: string,
		deliveryType: string,
		deliveryAddress: string,
		paymentType: string,
		promocode: string,
		rulesAgreed: boolean
	}

	interface FullOrder extends OrderValues {
		items: CartItem[];
		lang: string
	}

	interface Order {
		values: OrderValues
		rules: Rules
		errors: Errors
	}

	type FormFields = keyof OrderValues;

	let deliveryOptions = [
		{value: 'pakomat', label: `${$_('order.pakomat') } (+ €3.99)`},
		{value: 'courier', label: `${$_('order.courier') } (+ €6.99)`}
	];

	let paymentOptions = [
		{value: 'klix', label: `${$_('order.cardBank') } (Klix)`},
		{value: 'stripe', label: `${$_('order.cardBank') } (Stripe)`}
	];

	$: currentLang = $locale as Language;
	$: totalPrice = 0;

	interface Products {
		[id: number]: {
			image: string,
			price: number,
			name: string,
			link: string,
			variations: Variation[]
		}
	}

	let productIds: number[] = [];
	let products: Products = {};
	$items.forEach(item => productIds.push(item.id));

	if(productIds.length === 0) {
		navigate(`/${currentLang}`);
	}

	let productsData = getProductsByIds(`[${productIds.toString()}]`);
	productsData.then( data => {
		let normalData = normalizeData<OneProductFull>(
			data as unknown as WithID<OneProductFull[]>
		);

		products = $items.reduce((products, product) => {
			if(normalData) {
				return {
					...products,
					[product.id]: {
						image: normalData[product.id].mainImage.thumbs[0].url,
						price: normalData[product.id].specialPrice > 0 ? 
							normalData[product.id].specialPrice :
							normalData[product.id].price,
						name: normalData[product.id].name,
						link: normalData[product.id].link,
						variations: normalData[product.id].variations
					}
				};
			}
			return products;
		}, {} as Products);
		countTotal();
	});


	function countTotal() {
		let calculatedPrice = 0;
		$items.forEach(item => {
			calculatedPrice += products[item.id].price * item.quantity;
		});
		totalPrice = calculatedPrice;
	}

	const addNotification: AddNotification = getContext('addNotification') ;
	let deliveryPrice = 0;

	let orderData: Order = {
		values: {
			firstName: '',
			lastName: '',
			email: '',
			phone: '',
			comments: '',
			deliveryType: '',
			deliveryAddress: '',
			paymentType: '',
			promocode: '',
			rulesAgreed: false
		},
		rules: {
			firstName: {
				required: { 
					value: true,
					errorText: $_('errors.required.name')
				}
			},
			lastName: {
				required: {
					value: true,
					errorText: $_('errors.required.surname')
				}
			},
			email: {
				required: {
					value: true,
					errorText: $_('errors.required.email')
				},
				pattern: {
					value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
					errorText: $_('errors.pattern.email')
				}
			},
			phone: {
				required: {
					value: true,
					errorText: $_('errors.required.phone')
				},
				pattern: {
					value: /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/,
					errorText: $_('errors.pattern.phone')
				}
			},
			deliveryType: {
				required: {
					value: true,
					errorText: $_('errors.required.delivery')
				}
			},
			paymentType: {
				required: {
					value: true,
					errorText: $_('errors.required.payment')
				}
			},
			rulesAgreed: {
				required: {
					value: true,
					errorText: $_('errors.required.rules')
				}
			}
		},
		errors: {
			firstName: [],
			lastName: [],
			email: [],
			phone: [],
			comments: [],
			deliveryType: [],
			deliveryAddress: [],
			paymentType: [],
			promocode: [],
			rulesAgreed: []
		}
	};

	$: if (orderData.values.deliveryType ==='courier') {
		deliveryPrice = 6.99;
		orderData.rules.deliveryAddress = {
			required: {
				value: true,
				errorText: $_('errors.required.address')
			}
		};
	} else if (orderData.values.deliveryType ==='pakomat') {
		deliveryPrice = 3.99;
		orderData.rules.deliveryAddress = {
			required: {
				value: true,
				errorText: $_('errors.required.pakomat')
			}
		};
	} else {
		deliveryPrice = 0;
		delete orderData.rules.deliveryAddress;
	}

	function validateField(key: FormFields) {
		let currentValue = orderData.values[key];
		let rules = orderData.rules[key] as Rule;

		orderData.errors[key] = [];

		if (typeof currentValue === 'boolean') {
			if(rules.required?.value && !currentValue) {
				orderData.errors[key] = [...orderData.errors[key],
					rules.required.errorText];
			}

			return;
		}
		
		if (rules.required?.value && !currentValue) {
			orderData.errors[key] = [...orderData.errors[key],
				rules.required.errorText];

			return;
		}

		if(rules.pattern?.value && !currentValue.match(rules.pattern?.value)) {
			orderData.errors[key] = [...orderData.errors[key],
				rules.pattern.errorText];
		}
	}

	let pakomats: OmnivaJSON[] | undefined = undefined;

	fetch('https://www.omniva.lv/locations.json').
		then(response => response.json()).
		then(response => {
			pakomats = response as unknown as OmnivaJSON[];
		});
	
	function loadOmnivaAddresses(pakomats: OmnivaJSON[]) {
		let lvPakomats = pakomats.filter((element: OmnivaJSON) => {
			return element.A0_NAME === 'LV';
		});

		return lvPakomats.map(pakomat => {
			return {value: String(pakomat.NAME), label: pakomat.NAME};
		});
	}

	function validateForm() {
		Object.keys(orderData.rules).map((key) => {
			validateField(key as FormFields);
		});
	}

	let isSendingOrder = false;

	function sendOrder() {
		validateForm();
		let isValid = true;
		Object.keys(orderData.errors).map(key => {
			if (orderData.errors[key].length > 0) {
				if (isValid) {
					window.scrollTo({top: 0, behavior: 'smooth'});
				}
				isValid = false;
				return;
			}
		});

		if(!isValid) {
			return addNotification({
				type: 'error',
				text: $_('order.wrongForm')
			});
		}

		isSendingOrder = true;
		let itemsToSend = $items.map((item => {
			return {
				id: item.id,
				variationId: item.attributes,
				quantity: item.quantity
			};
		})) as unknown as CartItem[];
		let fullOrder: FullOrder = {
			...orderData.values,
			items: itemsToSend,
			lang: localStorage.getItem('lang') as string
		};
		
		createOrder(fullOrder).then((response) => {
			navigate(response as string);
			clearCart();
		}).catch((reason) => {
			addNotification({
				type: 'error',
				text: $_('errorOccured')
			});
		}).finally(() => {
			isSendingOrder = false;
		});
	}

	meta.set({
		title: $_('order.placeOrder'),
		description: '',
		keywords: ''
	});
</script>

<header>
	<h1>{$_('order.placeOrder')}</h1>
</header>
<div class="wrapper">
	<div>
		<section>
			<article class="white">
				<div class="person">
					<Input 
						label={$_('user.name')}
						bind:value={orderData.values.firstName}
						bind:errors={orderData.errors.firstName}
						on:input={() => {validateField('firstName');}}
						placeholder="Jānis"
						maxLength={32}
					/>
					<Input
						label={$_('user.surname')}
						bind:value={orderData.values.lastName}
						bind:errors={orderData.errors.lastName}
						on:input={() => {validateField('lastName');}}
						placeholder="Bērziņš"
						maxLength={32}
					/>
					<Input
						label={$_('user.email')}
						bind:value={orderData.values.email}
						bind:errors={orderData.errors.email}
						on:input={() => {validateField('email');}}
						placeholder="janis.berzins@inbox.lv"
						maxLength={64}
					/>
					<Input 
						label={$_('user.phone')}
						bind:value={orderData.values.phone}
						bind:errors={orderData.errors.phone}
						on:input={() => {validateField('phone');}}
						placeholder="+37167016200"
						maxLength={14}
					/>
				</div>
			</article>
		</section>
		<section>
			<article class="white">
				<div class="delivery">
					<Radio
						label={$_('order.chooseDelivery')}
						data={deliveryOptions} 
						bind:userSelected={orderData.values.deliveryType}
						bind:errors={orderData.errors.deliveryType}
						on:change={() => {
							validateField('deliveryType');
							orderData.errors.deliveryAddress = [];
							orderData.values.deliveryAddress = '';
						}}
					/>
					{#if orderData.values.deliveryType === 'courier'}
						<Input 
							label={$_('user.address')}
							bind:value={orderData.values.deliveryAddress}
							bind:errors={orderData.errors.deliveryAddress}
							on:input={() => {validateField('deliveryAddress');}}
							placeholder="Rīga, Brīvības iela 100, dz. 5, LV-1020"
							maxLength={128}
						/>
					{:else if orderData.values.deliveryType === 'pakomat'}
						{#if pakomats}
							<FormItem label={$_('order.choosePakomat')} errors={orderData.errors.deliveryAddress}>
								<SelectTwo
									items={loadOmnivaAddresses(pakomats)}
									bind:value={orderData.values.deliveryAddress}
									hasError={orderData.errors.deliveryAddress.length > 0}
								/>
							</FormItem>
						{:else}
							<!-- <NewSelect label={$_('order.choosePakomat')} /> -->
						{/if}
					{:else if orderData.values.deliveryType === 'self'}
						<span>Rīga, Brīvības gatve 250, {$_('howToFindUs.gsVirsi')}</span>
					{/if}
				</div>
			</article>
		</section>
		<section>
			<article class="white">
				<div class="payment">
					<Radio
						label={$_('order.choosePayment')}
						data={paymentOptions}
						bind:userSelected={orderData.values.paymentType}
						bind:errors={orderData.errors.paymentType}
						on:change={() => {validateField('paymentType');}}
					/>
				</div>
			</article>
		</section>
		<section>
			<article class="white">
				<div class="other">
					<Input 
						label={$_('order.promo')}
						bind:value={orderData.values.promocode}
						bind:errors={orderData.errors.promocode}
						maxLength={32}
					/>
					<Textarea
						label={$_('order.comments')}
						bind:value={orderData.values.comments}
						maxLength={128}
					/>
					<Checkbox 
						label={$_('order.agreeRules')}
						bind:checked={orderData.values.rulesAgreed}
						bind:errors={orderData.errors.rulesAgreed}
						on:change={() => {validateField('rulesAgreed');}}
					/>
				</div>
			</article>
		</section>
	</div>
	<div>
		<section>
			<article class="items">
				{#await productsData}
					<Loader />
				{:then} 	
					{#each $items as item, i (i)}
						<div class="item">
							<div class="image">
								<Image src={products[item.id].image} alt={products[item.id].name} />
							</div>
							<div class="data">
								<span>{products[item.id].name}</span>
								<div class="details">
									<div class="attributes">
										{#each products[item.id].variations as variation}
											{#if variation.id === item.attributes}
												{#each variation.options as option}	
													<div>
														<span>{option.attribute.name[currentLang]}:</span>
														<span>{option.attributeValue.name[currentLang]}</span>
													</div>
												{/each}
											{/if}
										{/each}
									</div>
									<span>
										{item.quantity}
									</span>
									<span>&euro; {toMoney(products[item.id].price * item.quantity)}</span>
								</div>
							</div>
						</div>
					{/each}
				{/await}
			</article>
		</section>
		<section>
			<article>
				<CartSummary total={totalPrice} deliveryPrice={deliveryPrice}>
					<Button
						value={$_('order.pay')}
						isLoading={isSendingOrder}
						on:click={() => sendOrder()}
					/>
				</CartSummary>
			</article>
		</section>
	</div>
</div>

<style lang="scss">
    @import '../../styles/mixins';
	
	.wrapper {
		display: grid;
		grid-gap: 20px;
		
		@include desktop {
			grid-template-columns: repeat(2, 1fr);
		}
		
		>* {
			display: flex;
			flex-direction: column;
			gap: 20px;
		}

		.white {
			background: #fff;
			border-radius: 5px;
			padding: 20px;
		}
	}

	.person,
	.delivery,
	.payment,
	.other {
		display: flex;
		flex-direction: column;
		gap: 10px;
	}

	.items {
		display: grid;
		grid-gap: 10px;
		background: none !important; 

		.item {
			display: grid;
			grid-template-columns: min-content 1fr;
			grid-gap: 20px;
			
			@include mobile {
				background-color: #fff;
				padding: 10px;
				border-radius: 6px;
			}
	
			.image {
				width: 50px;
				height: auto;

				@include desktop {
					width: 80px;
					height: 80px;
				}
			}
	
			.data {
				display: grid;
				grid-template-rows: min-content 1fr;
				padding: 10px 0;
				grid-gap: 6px;
	
				> span:first-child {
					font-size: 17px;
					font-weight: 500;
				}
	
				.details {
					display: grid;
					grid-template-columns: repeat(3, 1fr);
					grid-gap: 10px;
					align-content: center;
					align-items: center;
	
					.attributes > div {
						display: flex;
						gap: 10px;
					}
	
					&:last-child {
						text-align: right;
					}
				}
			}
		}
	}

</style>