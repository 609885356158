<script lang="ts">
	import { cubicOut } from 'svelte/easing';
	import { scrollLocker } from 'Utils/general/utils';
	import {
		tweened,
		Tweened
	} from 'svelte/motion';
	import Image from 'Components/primitives/Image.svelte';
	import isMobile from 'Utils/general/deviceDetector';
	import PhotoBox from './PhotoBox.svelte';
	import type { OneProductFull } from 'Utils/products';

	export let photos: OneProductFull['images'];
	let openGallery = false;
	let mainImageIndex = 0;
	let initialX = 0;
	let dragged: Tweened<number> = tweened(0, {
		duration: 200,
		easing: cubicOut
	});
	let moved: Tweened<number> = tweened(0, {
		duration: 200,
		easing: cubicOut
	});

	function next() {
		const stopedInteger = Math.round($moved);
		if(stopedInteger + 1 < photos.length) {
			$moved = stopedInteger + 1;
			mainImageIndex += 1;
		}
	}

	function previous() {
		const stopedInteger = Math.round($moved);
		if(stopedInteger > 0) {
			$moved = stopedInteger - 1;
			mainImageIndex -= 1;
		}
	}

	function handleTouchStart(e: TouchEvent) {
		scrollLocker(true);
		if(e.touches.length === 1) {
			initialX = e.changedTouches[0].screenX;
		}
	}

	function handleTouchMove(e: TouchEvent) {
		if(e.touches.length === 1) {
			$dragged = e.changedTouches[0].screenX - initialX;
		}
	}

	function handleTouchEnd(e: TouchEvent) {
		scrollLocker(false);
		if($dragged > 50) {
			previous();
		} 
		
		if ($dragged < -50) {
			next();
		}

		$dragged = 0;
	}
</script>

<div class="gallery">
	<div class="carousel"
		on:touchstart|passive={handleTouchStart}
		on:touchmove|passive={handleTouchMove}
		on:touchend|passive={handleTouchEnd}
	>
		{#each photos as image, i}
			<div
				class="slide"
				style="transform: translateX(calc(-{$moved * 100}% + {$dragged}px));">
				<Image
					src={image.thumbs[image.thumbs.length - 2].url}
					alt="Name ${i}"
					on:click={() => {
						mainImageIndex = i;
						openGallery = true;
					}}
				/>
			</div>
		{/each}
		<div class="index">{mainImageIndex + 1} / {photos.length}</div>
	</div>
	{#if !isMobile}
		<div class="previews">
			{#each photos as image, i}
				<Image
					src={image.thumbs[0].url}
					alt="Name ${i}"
					on:click={() => {$moved = i; mainImageIndex = i;}}
				/>
			{/each}
		</div>
	{/if}
</div>
<PhotoBox images={photos} bind:ifOpened={openGallery} selectedInitial={mainImageIndex} />

<style lang="scss">
    @import '../../styles/mixins';

	.gallery {
        display: flex;
        gap: 8px;
        flex-direction: column;

        .carousel {
			width: 100%;
			height: max-content;
			display: flex;
			overflow: hidden;
			cursor: pointer;

			.slide {
				width: 100%;
				will-change: transform;
				aspect-ratio: 1/1;
				flex: none;

				:global(img) {
					background-color: #fff;
					aspect-ratio: 1/1;
					object-fit: cover;
				}
			}

			.index {
				font-size: 13px;
				display: block;
				position: absolute;
				left: 8px;
				bottom: 8px;
				padding: 4px 8px;
				background-color: rgba(255, 255, 255, 0.6);
				border-radius: 7px;
			}
        }
        
        .previews {
            display: grid;
            grid-gap: 8px;
            grid-template-columns: repeat(4, 1fr);
            grid-template-rows: repeat(auto-fill, minmax(min-content, max-content));
            cursor: pointer;

            @include desktop {
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                grid-gap: 8px;
            }

            > :global(img) {
				background-color: #fff;
                height: auto;
                aspect-ratio: 1 / 1;
				object-fit: cover;
            }
        }
    }
</style>
