<script lang="ts">
	import {
		_,
		locale
	} from 'svelte-i18n';
	import { clickOutside } from 'Utils/general/clickOutside';
	import { createEventDispatcher } from 'svelte';
	import { 
		FilterField,
		filters
	} from 'Utils/categories/store';
	import { fly } from 'svelte/transition';
	import { query } from 'Utils/general/utils';
	import Button from 'Components/primitives/Button.svelte';
	import Form from 'Components/primitives/Form.svelte';
	import FormItem from 'Components/primitives/FormItem.svelte';
	import isMobile from 'Utils/general/deviceDetector';
	import RangeInput from 'Components/primitives/RangeInput.svelte';
	import
	Select,
	{ Option }
		from 'Components/primitives/Select.svelte';
	import type { AttributeValue } from 'Utils/products';
	import type { CategoryAttributes } from 'Utils/categories/types';
	import type { Language } from 'Utils/general/types';
	import type { WithID } from 'Utils/request/types';

	interface Brand {
		id: number;
		name: string;
	}

	let currentLang = $locale as Language;
	$: currentLang = $locale as Language;

	export let opened = false;
	export let filtersString: string | undefined;
	export let attributes: CategoryAttributes[];
	let brands: Option[] | undefined;
	let filterFields: FilterField = {};

	if (Object.keys($filters.filterArray).length < 0) {
		filterFields = {
			'brandId': undefined,
			'minPrice': undefined,
			'maxPrice': undefined
		};
	
		for (const attribute of attributes) {
			filterFields[`a_${attribute.attribute.id}`] = undefined;
		}
	} else {
		filterFields = $filters.filterArray;
	}

	const dispatch = createEventDispatcher();

	function clearAll(event: Event) {
		event.preventDefault();
		opened = false;
		filters.set({
			categoryId: NaN,
			filterArray: {},
			query: ''
		});
		Object.keys(filterFields).forEach(key => {
			filterFields[key] = undefined;
		});
	}

	function submitFilters() {
		opened = false;
		$filters.filterArray = filterFields;
		filtersString = Object.keys(filterFields).map((key) => {			
			if (filterFields[key]) {
				if (key === 'minPrice') {
					return `&price=>${filterFields[key]}`;
				}
	
				if (key === 'maxPrice') {
					return `&price=<${filterFields[key]}`;
				}
				return `&${key}=${filterFields[key]}`;
			} 
			return '';
			
		}).join('');
		dispatch('apply');
	}
	
	query<Brand[]>('/api/shop/brands').then(((message) => {
		brands = message.map((brand) => {
			return {
				value: String(brand.id),
				label: brand.name
			};
		});
	}));

	function prepareOptions(object: WithID<AttributeValue>[]) {
		return object.map((value) => {
			return {
				value: value.id,
				label: value.name[currentLang]
			};
		}) as unknown as Option[];
	}
</script>
<svelte:options immutable />
{#if opened}
	<div
		class="filters"
		transition:fly="{
			isMobile ? { y: 200, duration: 300} : {x: 200, duration: 300}
		}"
		on:click_outside={() => {opened = false;}}
		use:clickOutside
		>
		<Form on:submit={submitFilters}>
			{#if brands}
				<FormItem label={$_('shop.brand')}>
					<Select
						items={brands}
						bind:value={filterFields.brandId}
					/>
				</FormItem>
			{/if}
			{#each attributes as attribute}
				<FormItem label={attribute.attribute.name[currentLang]}>
					<Select
						items={prepareOptions(attribute.attribute.values)}
						bind:value={filterFields[`a_${attribute.attribute.id}`]}
					/>
				</FormItem>
			{/each}
			<FormItem label={$_('shop.price')}>
				<RangeInput bind:min={filterFields.minPrice} bind:max={filterFields.maxPrice} />
			</FormItem>
			<Button value={$_('accept')} />
			<Button value={$_('clearAll')} type="white" on:click={clearAll} />
		</Form>
	</div>
{/if}

<style lang="scss">
	@import '../../styles/mixins';

	.filters {
		position: fixed;
		background-color: #fff;
		z-index: 100;
		padding: 20px;
		box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.1);

		@include mobile {
			width: 100%;
			left: 0;
			right: 0;
			bottom: 0;
			border-radius: 10px 10px 0 0;
			padding-bottom: calc(20px + env(safe-area-inset-bottom))
		}

		@include after-mobile {
			width: 400px;
			right: 20px;
			border-radius: 10px;
			top: 200px;
		}
	}
</style>

