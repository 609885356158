export function toMoney(number) {
    return number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
}
export function normalizeData(data) {
    if (!('id' in data[0])) {
        return {};
    }
    const normalized = {};
    for (let i = 0; i < data.length; i++) {
        normalized[data[i].id] = data[i];
    }
    return normalized;
}
export const getFormData = (data) => Object.entries(data).
    reduce((formData, [key, value]) => {
    formData = setObjectFormData(formData, key, value);
    return formData;
}, new FormData());
const setObjectFormData = (formData, key, value) => {
    if (!value) {
        if (typeof value === 'number') {
            formData.set(key, String(value));
        }
        else {
            return formData;
        }
    }
    if (typeof value === 'object') {
        Object.entries(value).forEach(([nestedKey, nestedValue]) => {
            formData = setObjectFormData(formData, `${key}[${nestedKey}]`, nestedValue);
        });
    }
    else {
        formData.set(key, value);
    }
    return formData;
};
export const isNetworkError = (error) => {
    if (error instanceof TypeError) {
        return error.message.match(/NetworkError/);
    }
    return false;
};
export const query = async (url) => fetch(url, {
    mode: 'same-origin'
}).then(async (response) => {
    if (!response.ok) {
        throw response.status;
    }
    const json = await response.json();
    return json.message;
});
export const postQuery = async (url, payload) => fetch(url, {
    method: 'POST',
    mode: 'same-origin',
    cache: 'no-cache',
    body: getFormData(payload)
}).
    then(async (response) => {
    if (!response.ok) {
        throw response.status;
    }
    const json = await response.json();
    return json.message;
});
export function compareObjects(object1, object2) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
    if (keys1.length !== keys2.length) {
        return false;
    }
    for (const key of keys1) {
        if (object1[key] !== object2[key]) {
            return false;
        }
    }
    return true;
}
export function scrollLocker(locked) {
    if (locked) {
        document.body.style.cssText = `
			overflow:hidden;
			position:fixed;
			left: 0;
			right: 0;
			top: -${window.scrollY}px;
		`;
        window.scrollTo(0, 1);
        return;
    }
    const savedScrollPsn = document.body.style.top;
    document.body.removeAttribute('style');
    window.scrollTo(0, parseInt(savedScrollPsn || '0') * -1);
}
