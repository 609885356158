<script lang="ts">
	import { _ } from 'svelte-i18n';
	export let min: string | undefined = undefined;
	export let max: string | undefined = undefined;
</script>

<div class="range">
	<input type="nubmer" bind:value={min} placeholder="{$_('from')}" />
	<span> - </span>
	<input type="nubmer" bind:value={max}  placeholder="{$_('to')}" />
</div>

<style lang="scss">
	.range {
		display: flex;
		align-items: center;
		gap: 10px;

		input {
			background-color: #fff;
			border-radius: 5px;
			border: 1px solid #d8dbdf;
			font-size: 16px;
			padding: 8px 12px;
			width: 100%;
			height: 42px;
			transition: all .2s ease-in-out;
		}
	}
</style>