import { query } from 'Utils/general/utils';
export async function getNewProducts(limit) {
    return query(`/api/shop/products/list?order=createdAt,desc&limit=${limit}`);
}
export async function getCategoryProducts(id, offset, limit, order = 'id,desc', filters) {
    return query(`/api/shop/products/list?
		categoryId=${id}
		&offset=${offset}
		&limit=${limit}
		&order=${order}${filters}`);
}
export async function getProductsBySearch(input) {
    return query(`/api/shop/products/list?name=~${input}&limit=5`);
}
export async function getSpecialProducts(limit) {
    return query(`api/shop/products/list?order=rand&specialPrice=!null&limit=${limit}`);
}
export async function getProductsByIds(ids) {
    return query(`/api/shop/products?id=${ids}`);
}
export async function getOneProduct(link) {
    return query(`/api/shop/products/link/${link}`);
}
export async function getProductQuantity(categoryId, filters) {
    return query(`/api/shop/products/countlist?categoryId=${categoryId}
		?order=id,desc${filters}`);
}
