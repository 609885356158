import 'Utils/general/utils';
import { writable } from 'svelte/store';
const encodedCartItems = localStorage.getItem('cart');
const cartItems = encodedCartItems ? JSON.parse(encodedCartItems) : [];
export function addToCart(id, attributes, quantity) {
    let ifExists = false;
    items.update((current) => {
        current.forEach((item, i) => {
            if (item.id === id && item.attributes === attributes) {
                ifExists = true;
                current[i].quantity += 1;
                return;
            }
        });
        if (!ifExists) {
            current = [...current,
                {
                    id: id,
                    attributes: attributes,
                    quantity: quantity
                }
            ];
            quantityInCart.update(n => n + 1);
        }
        localStorage.setItem('cart', JSON.stringify(current));
        return current;
    });
}
export function clearCart() {
    localStorage.removeItem('cart');
    items.set([]);
    quantityInCart.set(0);
}
export function deleteFromCart(i) {
    items.update((current) => {
        const newArray = [...current.slice(0, i),
            ...current.slice(i + 1)];
        if (newArray.length > 0) {
            localStorage.setItem('cart', JSON.stringify(newArray));
        }
        else {
            localStorage.removeItem('cart');
        }
        return newArray;
    });
    quantityInCart.update(n => n - 1);
}
export function changeQuantity(i, increase) {
    items.update((current) => {
        if (current[i].quantity === 1 && !increase) {
            current[i].quantity = 1;
        }
        else {
            current[i].quantity += increase ? 1 : -1;
        }
        localStorage.setItem('cart', JSON.stringify(current));
        return current;
    });
}
export const items = writable(cartItems);
export const quantityInCart = writable(cartItems ? cartItems.length : 0);
