<script lang="ts">
import { _ } from 'svelte-i18n';

export let label: string;
export let errors: string[] = [];
export let value: string;
export let maxLength = 32;
export let placeholder = $_('shop.startTyping');

</script>

<div class="input">
    <span class="label">{label}</span>
    <label>
        <input class="{errors.length > 0 ? 'error' : ''} field"
            {maxLength}
            bind:value={value} 
            placeholder={placeholder}
            on:input
        />
        <div class="icon">
            <slot class="icon" name="icon" />
        </div>
        {#if errors.length > 0}
            {#each errors as error}
                <span class="error-text">{error}</span>
            {/each} 
        {/if}
    </label>
</div>

<style lang="scss">
    @import '../../styles/mixins';
    
    .input {
        display: grid;
        align-items: center;
        grid-gap: 6px;

        @include desktop {
            grid-template-columns: 1fr 300px;
        }
    }

    .error-text {
        color: #EB0000;
        margin: 4px 0 0 10px;
        display: block;
    }

    .error {
        border: 1px solid #EB0000 !important;
    }
    
    .field {
        background-color: #fff;
        border-radius: 5px;
        border: 1px solid #d8dbdf;
        font-size: 16px;
        padding: 8px 12px;
        width: 100%;
        height: 42px;
        transition: all .2s ease-in-out;

        &:focus {
            border: 1px solid #dc143c;
        }

        &:hover:not(:focus) {
            border: 1px solid #b2b8bf;
        }
    }

    .icon {
        position: absolute;
        top: 8px;
        right: 10px;
        width: 22px;
        opacity: .6;
    }
</style>